import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { SMemberService } from '@shop/service/member.service';
import { SBoardService } from '@shop/service/board.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { McompanySearchComponent } from '@mobile/page/member/member-join/company-search/company-search.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-mmember-join',
  templateUrl: './member-join.component.html',
  styleUrls: ['./member-join.component.scss']
})
export class MmemberJoinComponent implements OnInit {

  daumAddressOptions =  {
		class: ['btn', 'overlap-btn', 'h42', 'w60px'],
		text: '주소찾기'
  };

  public checkIdDup: boolean = true;
  public inputCustomerReadonly: boolean = false;

  errorResponse: ApiResponse;
  public memberAddForm: FormGroup;
  public formErrors = {};

  public privacyData: SafeHtml;
  public termsData: SafeHtml;

  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private sanitized: DomSanitizer,
    private sMemberService: SMemberService,
    private sBoardService: SBoardService,
    private authService: AuthService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.memberAddForm = this.formBuilder.group({
      agree1:[ false, [Validators.required] ],
      agree2:[ false, [Validators.required] ],
      all_check:[ false, [] ],
      name:[ '', [Validators.required, Validators.pattern('^[가-힣a-zA-Z]*$')] ],
      id:[ '', [Validators.required, Validators.pattern('^[a-z0-9]{6,20}$')] ],
      id_check:[ false, [Validators.required] ],
      pw:[ '', [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{5,19}$')] ],
      pw_check:[ '', [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{5,19}$')] ],
      //email1:[ '', [Validators.required, Validators.pattern('^[a-z0-9._-]*$')] ],
      //email2:[ '', [Validators.required, Validators.pattern('^[a-z0-9]+\\.[a-z]{2,3}$')] ],
      email1:[ '', [Validators.required] ],
      email2:[ '' ],
      hp1:[ '010', [Validators.required] ],
      hp2:[ '', [Validators.required, Validators.pattern('^[0-9]{7,8}$')] ],
      customer_seq:[ '' ],
      customer_name:[ '' ],
      customer_type:[ '0001' ],
      customer_kind:[ '0002' ],
      business_no:[ '', [Validators.pattern('^[0-9]{10}$')] ],
      customer_tel:[ '', [Validators.pattern('^[0-9]{9,}$')] ],
      taxinvoice_email1:[ '' ],
      taxinvoice_email2:[ '' ],
      business_type:[ '' ],
      business_item:[ '' ],
      customer_zipcode:[ '' ],
      customer_address:[ '' ],
      customer_address_detail:[ '' ],
    });

    this.memberAddForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.memberAddForm, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
		$('.mheader>.left-area').addClass('blind');
		$('.mheader>.right-area').addClass('blind');
		$('.mfooter').addClass('blind');
  }

  ngOnDestroy() {
   	$('.mheader>.left-area').removeClass('blind');
   	$('.mheader>.right-area').removeClass('blind');
  	$('.mfooter').removeClass('blind'); 
  }

	memberinfo() {
    if( this.memberAddForm.get('agree1').value == false ) {
      this.toastrService.warning( '이용약관 동의는 필수 입력(선택) 항목입니다', '');
      return false;
    }

    if( this.memberAddForm.get('agree2').value == false ) {
      this.toastrService.warning( '개인정보수집 및 이용 동의는 필수 입력(선택) 항목입니다', '');
      return false;
		}
		
		$('.agree-area').css('display', 'none');
		$('.member-info').css('display', 'block');
	}

  all_check() {
		this.memberAddForm.patchValue({
			agree1: true,
			agree2: true
		});
	}

  ngDoCheck() {
    // 이메일 선택에 따른 패턴 부여
    if( this.memberAddForm.get('email2').value == '' ) {
      this.memberAddForm.get('email1').setValidators( [Validators.required, Validators.pattern('^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')] );
      this.memberAddForm.get('email1').updateValueAndValidity();
    } else {
      this.memberAddForm.get('email1').setValidators( [Validators.required, Validators.pattern('^[a-z0-9._-]*$')] );
      this.memberAddForm.get('email1').updateValueAndValidity();
    }

    // 이메일 선택에 따른 패턴 부여
    if( this.memberAddForm.get('taxinvoice_email2').value == '' ) {
      this.memberAddForm.get('taxinvoice_email1').setValidators( Validators.pattern('^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$') );
      this.memberAddForm.get('taxinvoice_email1').updateValueAndValidity();
    } else {
      this.memberAddForm.get('taxinvoice_email1').setValidators( Validators.pattern('^[a-z0-9._-]*$') );
      this.memberAddForm.get('taxinvoice_email1').updateValueAndValidity();
    }
  }

  /*******************************************************************************
    설  명 : 아이디 중복 검사
  *******************************************************************************/
	checkIdDuplicate() {
		this.utilService.makeFieldDirtyAndUpdateErrors(this.memberAddForm, this.formErrors, 'id');

		if( this.memberAddForm.get('id').valid ) {
			this.sMemberService.checkIdDuplicate( this.memberAddForm.get( 'id' ).value ).then( response => {
				if( response.ResultCode ) {
					this.memberAddForm.get( 'id_check' ).setValue( true );
					this.toastrService.success( response.ResultMessage, '아이디 중복검사');
				} else {
					this.memberAddForm.get( 'id_check' ).setValue( false );
					this.toastrService.error( response.ResultMessage, '아이디 중복검사');
				}
			});
		}
	}

	// 아이디 변경시 중복 검사 초기화
	setIdCheckInit() {
		this.memberAddForm.get( 'id_check' ).setValue( false );
	}

	/*******************************************************************************
		설  명 : 다음 주소 처리
	*******************************************************************************/
	setDaumAddressApi(data) {
		// 여기로 주소값이 반환
		this.memberAddForm.patchValue({
			customer_zipcode: data.zip,
			customer_address: data.addr
			//customer_address: "(" + data.zip + ") " + data.addr
		});

		$('#customer_address_detail').focus();
	}

	/*******************************************************************************
		설  명 : 회사검색
	*******************************************************************************/
	searchCustomer() {
		const modalRef = this.modalService.open(McompanySearchComponent, options);

		//modalRef.componentInstance.selectedItem = item;

		modalRef.componentInstance.emitData.subscribe(($e) => {
			this.memberAddForm.get('customer_seq').setValue( $e.seq );
			this.memberAddForm.get('customer_name').setValue( $e.name );
			this.memberAddForm.get('customer_type').setValue( $e.type );
			this.memberAddForm.get('customer_kind').setValue( $e.kind );
			this.memberAddForm.get('business_no').setValue( $e.business_no.replace(/-/g,'') );
			this.memberAddForm.get('customer_tel').setValue( $e.tel.replace(/-/g,'') );
			this.memberAddForm.get('taxinvoice_email1').setValue( $e.taxinvoice_email );
			this.memberAddForm.get('business_type').setValue( $e.business_type );
			this.memberAddForm.get('business_item').setValue( $e.business_item );
			this.memberAddForm.get('customer_zipcode').setValue( $e.zipcode );
			this.memberAddForm.get('customer_address').setValue( $e.address );
			this.memberAddForm.get('customer_address_detail').setValue( $e.address_detail );

			this.inputCustomerReadonly = true;
		});

		modalRef.result.then((result) => {
		}, (reason) => {
		});
	}

	/*******************************************************************************
		설  명 : 회사정보 입력폼 초기화
	*******************************************************************************/
	initCustomer() {
		this.memberAddForm.get('customer_seq').setValue( '' );
		this.memberAddForm.get('customer_name').setValue( '' );
		this.memberAddForm.get('customer_type').setValue( '' );
		this.memberAddForm.get('customer_kind').setValue( '' );
		this.memberAddForm.get('business_no').setValue( '' );
		this.memberAddForm.get('customer_tel').setValue( '' );
		this.memberAddForm.get('taxinvoice_email1').setValue( '' );
		this.memberAddForm.get('taxinvoice_email2').setValue( '' );
		this.memberAddForm.get('business_type').setValue( '' );
		this.memberAddForm.get('business_item').setValue( '' );
		this.memberAddForm.get('customer_zipcode').setValue( '' );
		this.memberAddForm.get('customer_address').setValue( '' );
		this.memberAddForm.get('customer_address_detail').setValue( '' );

		this.inputCustomerReadonly = false;
	}

	/*******************************************************************************
		설  명 : 회원가입 처리
	*******************************************************************************/
	public submit() {
		this.utilService.makeFormDirtyAndUpdateErrors(this.memberAddForm, this.formErrors);

		if( this.memberAddForm.valid ) {
			this.sMemberService.setMemberSave( this.memberAddForm ).then(response => {
				if( response.ResultCode == true ) {
					this.toastrService.success(response.ResultMessage, '회원가입 성공');

					// 로그인 처리
					this.sMemberService.setLogin( this.memberAddForm ).then(response => {
						if( response.ResultCode == true ) {
							this.authService.login(response);

							this.router.navigate(['/mobile/member/join/complete']);
						} else {
							this.toastrService.error(response.ResultMessage, '회원가입');
						}
					})
					.catch(response => {
						this.errorResponse = response;
						this.utilService.handleFormSubmitError(this.errorResponse, this.memberAddForm, this.formErrors);
					});

				} else {
					this.toastrService.error(response.ResultMessage, '회원가입 실패');
				}
			})
			.catch(response => {
				this.errorResponse = response;
				this.utilService.handleFormSubmitError(this.errorResponse, this.memberAddForm, this.formErrors);
			});
		}
	}

}
