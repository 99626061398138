import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { SOrderService } from '@shop/service/order.service';
import { SBoardService } from '@shop/service/board.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { SCancelEditComponent } from '@shop/page/member/mypage/cancel-list/edit/cancel-edit.component';
import { SCancelDetailComponent } from '@shop/page/member/mypage/cancel-list/detail/cancel-detail.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-mcancel-list',
  templateUrl: './cancel-list.component.html',
  styleUrls: ['./cancel-list.component.scss']
})
export class McancelListComponent implements OnInit {

	public refundList: any = [];

  public boardData: any = {};

  public params: any = {
    row: 10,
    no: 1,
    refundSeq: ''
  };

  public totalCount: number;

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sOrderService: SOrderService,
    private sBoardService: SBoardService,
    private modalService: NgbModal,
    private domSanitizer: DomSanitizer
  ) { }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit() {
    $("#mmypage-menu6").addClass("active");

    this.getBoardViewIndex();

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);

      this.getRefundList();
    });
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }

  /*******************************************************************************
    설  명 : 게시글 정보 가져오기
    입력값 : 게시판 아이디, 게시판 코드
    리턴값 : 없음
  *******************************************************************************/
  getBoardViewIndex() {
    this.sBoardService.getBoardViewIndex( 'contents', '1000000080000000000' ).then( response => {
      if( response.ResultCode ) {
        this.boardData = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '취소/환불 안내' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 환불내역 리스트 가져오기
  *******************************************************************************/
  async getRefundList() {
    await this.sOrderService.getRefundList( this.params ).then( response => {
      if( response.ResultCode ) {
        this.refundList = response.data;

        this.totalCount = parseInt( response.data.length );
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.params.no = page;

    this.router.navigate(
    ['/mobile/member/mypage/cancel'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 상세보기
  *******************************************************************************/
  view( row: any ) {
    const modalRef = this.modalService.open(SCancelDetailComponent, options);

    modalRef.componentInstance.refundSeq = row.seq;

    modalRef.result.then((result) => {
      this.getRefundList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 수정
  *******************************************************************************/
  modify( row: any ) {
    const modalRef = this.modalService.open(SCancelEditComponent, options);

    modalRef.componentInstance.refundSeq = row.seq;

    modalRef.result.then((result) => {
      this.getRefundList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 취소
  *******************************************************************************/
  delete( row: any ) {
    if( !confirm("선택하신 환불 요청을 취소하시겠습니까?") ) return false;

    this.sOrderService.setRefundCancel( row.seq ).then(response => {
      if( response['ResultCode'] == true ) {
        this.toastrService.success(response['ResultMessage'], '환불 요청 취소');
        this.getRefundList();
      } else {
        this.toastrService.error(response['ResultMessage'], '환불 요청 취소');
      }
    })
    .catch(response => {
      this.toastrService.error(response, '환불 요청 취소');
    });
  }

  /*******************************************************************************
    설  명 : 주문가이드 텍스트 처리
    입력값 : 없음
    리턴값 : SafeHtml
  *******************************************************************************/
  getSafeHtml( html: any ): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

}
