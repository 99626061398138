import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { config } from '@app/service/config.service';

@Component({
  selector: 'app-mboard-list',
  templateUrl: './board-list.component.html',
  styleUrls: ['./board-list.component.scss']
})
export class MboardListComponent implements OnInit {

  @Input() data: any;
  @Input() boardInfo: any;
  @Input() params: any;

  public baseURL: string = config.baseUrl;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
	) { }

  ngOnInit() {
  }

  view( row: any ) {
    this.params.board_seq = row.board_seq;

    this.router.navigate(
    ['/mobile/board/view'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

}
