import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { SMemberService } from '@shop/service/member.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss']
})
export class SCompanySearchComponent implements OnInit {
	@Output() emitData = new EventEmitter();

	errorResponse: ApiResponse;
	public searchCustomerForm: FormGroup;
  public formErrors = {
		'searchText': ''
	};

	public customerList = [];
	public customerIndex: any = -1;
	public searchCheck: boolean = false;

  constructor(
		private utilService: UtilService,
		private toastrService: ToastrService,
		private formBuilder: FormBuilder,
		public activeModal: NgbActiveModal,
		public memberService: SMemberService,
		public authService: AuthService,
	) {
		this.buildForm();
	}

  /*******************************************************************************
    설  명 : 폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.searchCustomerForm = this.formBuilder.group({
      searchText: ["", [Validators.required]],
    });

    this.searchCustomerForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.searchCustomerForm, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {

  }

  /*******************************************************************************
    설  명 : 적용하기
  *******************************************************************************/
  submitSelectedCustomer() {
    if( this.customerIndex == -1 ) {
      this.toastrService.warning( '적용할 회사를 선택하시기 바랍니다.', '');
      return false;
    }

    this.emitData.next( this.customerList[this.customerIndex] );

		this.activeModal.close();
  }

  /*******************************************************************************
    설  명 : 회사검색 처리
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.searchCustomerForm, this.formErrors);

    if( this.searchCustomerForm.valid ) {
			this.customerList = [];
      this.memberService.getSearchCustomer( this.searchCustomerForm )
			.then( response => {
        if( response.ResultCode ) {
					this.customerList = response.data;
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }

				this.searchCheck = true;
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.searchCustomerForm, this.formErrors);
      });
    }
  }

}
