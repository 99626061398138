import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { AuthService } from '@app/service/auth.service';
import { config } from '@app/service/config.service';
import { Globals } from '@app/service/globals.service';
import { DiscountService } from '@app/service/discount.service';

import { SProjectService } from '@shop/service/project.service';
import { SProductService } from '@shop/service/product.service';
import { SBoardService } from '@shop/service/board.service';
import { SOrderService } from '@shop/service/order.service';
import { SCartService } from '@shop/service/cart.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { SCCartModalComponent } from '@shop/components/cart-modal/cart-modal.component';

const optionsSM: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-mgoods-detail',
  templateUrl: './goods-detail.component.html',
  styleUrls: ['./goods-detail.component.scss']
})
export class MgoodsDetailComponent implements OnInit {

  isLogin$: Observable<boolean>;
  isLogIn: boolean;

  public baseURL: string = config.baseUrl;

	public projectSeq: any = '';

  public selectedImage: string = '';
  public selectedImageSeq: string = '';

  public isChecked: boolean = false;
  public count: any = 1;
  public totalCount: number;

  public productData: any = {
    discount: { discount_rate: 0, total_amt: 0 }
  };
  public upload: any = [];
  public categoryList: any = [];
  public setProduct: any = [];
  public boardList = [];
  public tagList = [
    {seq: '', name: '', discount: { discount_rate: 0, total_amt: 0 }, file_path: '', thumbnail_filename: '', sale_amt: 0}
  ];

  public orderGuide: any = {
    contents: '관리자에서 주문가이드를 작성하시기 바랍니다.'
  };

  public params: any = {
    seq: '',
    type: 'list',
    board_id: 'install',
    group_id: '전체',
    row: 10,
    no: 1,
    group: 'tag',
    text: ''
  };

  public referrer: any = {
    productSeq: '',
    categoryCode: '',
  };

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sProjectService: SProjectService,
    private sProductService: SProductService,
    private sBoardService: SBoardService,
    private sOrderService: SOrderService,
    private sCartService: SCartService,
    private authService: AuthService,
    private cookieService: CookieService,
    private modalService: NgbModal,
    private domSanitizer: DomSanitizer,
    private discountService: DiscountService,
    private globals: Globals
  ) {
    this.isLogin$ = this.authService.getIsLogin;

    this.isLogin$.subscribe( login => {
      this.isLogIn = login;
    });
  }

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
  	$(".mfooter").css("padding", "30px 20px 80px");
  	$(".goods").css("width", $(".goods>li").length * 170 + "px");
    $(".swiper-pagination").css({"width":"47px", "bottom":"20px", "right":"20px", "left":"auto", "background-color":"#919191", "color":"#fff", "padding":"5px 10px","border-radius":"10px", "font-size":"13px"});  	
    
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.seq = ( typeof params.productSeq == 'undefined' || params.productSeq == '' ) ? '' : params.productSeq;
      this.referrer.productSeq = ( typeof params.productSeq == 'undefined' || params.productSeq == '' ) ? '' : params.productSeq;
      this.referrer.categoryCode = ( typeof params.categoryCode == 'undefined' || params.categoryCode == '' ) ? '' : params.categoryCode;

      this.getProductDetail();
    });

		// 프로젝트 번호 가져오기
    this.sProjectService.getProjectSeq.subscribe(data => {
      this.projectSeq = data;
    });

    // 주문가이드
    this.getOrderGuide();

    // 관련상품 가져오기
    this.getProductTagList();    
  }

  ngOnDestroy() {
  	$(".mfooter").css("padding", "30px 20px");
  }

  // 숫자증가
  qty_plus() {
    let tmp: any = $('.count').val();
    $('.count').val(parseInt( tmp ) + 1 );
  }

  // 숫자감소
  qty_minus() {
    let tmp: any = $('.count').val();

    $('.count').val(parseInt(tmp) - 1 );
    if ($('.count').val() == 0) {
        $('.count').val(1);
    }
  }

	// 주문창 닫기
	slide_down() {
	  $(".order-area").removeClass("slide");
	}
	
	// 주문창 열기
	slide_up() {
	  $(".order-area").addClass("slide");
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }

  /*******************************************************************************
    설  명 : 상품정보 가져오기
  *******************************************************************************/
  getProductDetail() {
    this.sProductService.getProductDetail( this.params ).then( response => {
      // html 참조 오류 때문
      response.data.discount = {
        discount_rate: 0,
        total_amt: 0
      };
      this.productData = response.data;

      if( response.ResultCode ) {
        let tmp: any = [{
          seq: response.data.seq,
          sale_amt: response.data.sale_amt
        }];

        this.discountService.getDiscountList( this.projectSeq, tmp ).then( result => {
          response.data.discount = result.product[0];

          this.productData.count = 1;

          this.upload = response.data.upload;
          if( this.upload.length > 0 ) {
            this.selectedImageSeq = this.upload[0].seq;
            this.selectedImage = this.upload[0].url;
          }

          this.categoryList = response.data.category_list;
          this.setProduct = response.data.setProduct;

          this.isChecked = true;

          // 설치사례 tag 검색
          this.params.text = this.productData.name;
          this.getBoardList();
        });

      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : 설치사례 리스트 가져오기
  *******************************************************************************/
  getBoardList() {
    this.sBoardService.getBoardList( this.params ).then( response => {
      if( response.ResultCode ) {
        this.boardList = response.data;
        this.totalCount = parseInt( response.total );
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문가이드 가져오기
  *******************************************************************************/
  getOrderGuide() {
    this.sBoardService.getBoardView('contents', '15', false ).then( response => {
      if( response.ResultCode ) {
        this.orderGuide = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문가이드 텍스트 처리
    입력값 : 없음
    리턴값 : SafeHtml
  *******************************************************************************/
  getOrderGuideText( html: any ): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  /*******************************************************************************
    설  명 : 관련상품 리스트 가져오기
  *******************************************************************************/
  getProductTagList() {
    this.sProductService.getProductTagList( this.params ).then( response => {
      if( response.ResultCode ) {
        let tmp: any = [];
        for( let item of response.data ) {
          tmp.push({
            seq: item.seq,
            sale_amt: item.sale_amt
          });

          item.discount = {
            discount_rate: 0,
            total_amt: 0
          };
        }

        this.tagList = response.data;

        this.discountService.getDiscountList( this.projectSeq, tmp ).then( result => {
          // 상품에 할인 정보 저장
          for( let item of result.product ) {
            for( let item2 of this.tagList ) {
              if( item.seq == item2.seq )
                item2['discount'] = item;
            }
          }
        }, error => {
          console.log( error );
        });

      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 관련상품 상세보기
  *******************************************************************************/
  view( row: any ) {

    const queryParams: any = {
      productSeq: '',
      categoryCode: ''
    };

    queryParams.productSeq = row.seq;
    queryParams.categoryCode = row.parent_category_code;

    this.router.navigate(
    ['/mobile/goods/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 수량 변경 시
  *******************************************************************************/
  changeCount( event ) {
    if( event.target.value < 1 ) {
      event.target.value = 1;
      this.clickCount(1);
    } else {
      this.productData.count = event.target.value;
    }
  }

  clickCount( value ) {
    this.count = this.count + value;

    if( this.count < 1 ) this.count = 1;
    this.productData.count = this.count;
  }

  /*******************************************************************************
    설  명 : 썸네일 이미지 클릭 시
  *******************************************************************************/
  clickThumbnailImage( row: any ) {
    this.selectedImageSeq = row.seq;
    this.selectedImage = row.url;
  }

  /*******************************************************************************
    설  명 : 장바구니 저장
  *******************************************************************************/
  setAddCartSingle( row: any, count: number ) {
    // 품절 상품인 경우 장바구니 담기 불가
    if( this.productData.stock_yn == false ) {
      this.toastrService.error('품절인 상품은 장바구니에 담을 수 없습니다.', '장바구니');
      return false;
    }

    // 회원인 경우
    if( this.isLogIn ) {
      this.sOrderService.setAddCartSingle( row ).then(response => {
        if( response['ResultCode'] == true ) {

        } else {
          this.toastrService.error(response['ResultMessage'], '장바구니');
        }
      })
      .catch(response => {
        this.toastrService.success(response, '장바구니');
      });

    // 비회원인 경우 쿠키에 담기
    } else {
      let data: any = this.globals.load( 'thefirstpro_mobile_cart' );
      let cart: any = ( data !== false ) ? data : [];
      let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];
      let cartListAdd: any = [];

      let check = false;

      // 장바구니에 상품이 있을 경우 수량만 변경
      if( cartList.length > 0 ) {
        for( let index in cartList ) {
          if( cartList[index].seq == row.seq ) {
            cartList[index].count = parseInt(row.count) + parseInt(cartList[index].count);

            check = true;
          }
        }
      }

      // 장바구니에 상품이 없을 경우 추가
      // 쿠키 저장용량 4k 초과할 수 없음. 최저 정보 저장
      if( ! check ) {
        cartList.push({
          seq: row.seq,
          count: row.count,
          sale_amt: row.discount.total_amt,
        });
      }

      this.globals.save( 'thefirstpro_mobile_cart', cartList );
    }

    // 안내 모달창 표시
    const modalRef = this.modalService.open(SCCartModalComponent, optionsSM);

    modalRef.result.then((result) => {
      // 카트 이동
      if( result == 'cart' ) {
        this.router.navigate(
        ['/mobile/member/mypage/cart'],
        {
          relativeTo: this.activatedRoute,
          queryParams: {refresh:'true'},
          queryParamsHandling: '', // remove to replace all query params by provided
        });

      // 계속 쇼핑, 창닫기 - 아무것도 안함
      } else {
      }
    }, (reason) => {
      console.log( reason );
    });
  }

  /*******************************************************************************
    설  명 : 빠른주문
  *******************************************************************************/
  setQuickOrder( row: any, count: number ) {
    // 품절 상품인 경우 담기 불가
    if( this.productData.stock_yn == false ) {
      this.toastrService.error('품절인 상품은 주문하실 수 없습니다.', '빠른주문');
      return false;
    }

    this.sCartService.setMask( true );
    //this.sCartService.setQuickOpen( true );
    //this.sCartService.setQuickOrder( row );
    this.sCartService.quickOpen.next( true );
    this.sCartService.setAddCart( row );    
  }

  /*******************************************************************************
    설  명 : 주문하기
  *******************************************************************************/
  setOrder() {
    this.toastrService.error('현재 모바일에선 주문하실 수 없습니다.', '주문하기');
    /*
    // 품절 상품인 경우 담기 불가
    if( this.productData.stock_yn == false ) {
      this.toastrService.error('품절인 상품은 주문하실 수 없습니다.', '주문');
      return false;
    }

    let data: any = [{
      seq: this.productData.seq,
      count: this.productData.count
    }];

    data = JSON.stringify( data );

    this.router.navigate(
    ['/order'],
    {
      relativeTo: this.activatedRoute,
      queryParams: {
        data: encodeURIComponent( data )
      },
      queryParamsHandling: '', // remove to replace all query params by provided
    });
    */
  }

}
