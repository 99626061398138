import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { SMemberService } from '@shop/service/member.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { SCompanySearchComponent } from '../../member-join/company-search/company-search.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-member-edit',
  templateUrl: './member-edit.component.html',
  styleUrls: ['./member-edit.component.scss']
})
export class SMemberEditComponent implements OnInit {
  @ViewChild('email2', {static: false}) inputEmail2: ElementRef;
  @ViewChild('taxinvoiceEmail2', {static: false}) inputTaxinvoiceEmail2: ElementRef;

  daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  public memberInfo: any = {};

  errorResponse: ApiResponse;
  public memberEditForm: FormGroup;
  public formErrors = {
    'id': '',
    'pw': '',
    'pw_check': '',
    'name': '',
    'hp1': '',
    'hp2': '',
    'email1': '',
    'email2': '',
    'business_no': '',
    'ceo': '',
    'customer_tel': '',
    'taxinvoice_email1': '',
    'taxinvoice_email2': '',
  };

  public email_array = [
    'naver.com',
    'gmail.com',
    'daum.net',
    'nate.com',
    'dreamwiz.comn',
    'yahoo.co.kr',
    'outlook.com',
    'hotmail.com',
  ];

  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private sMemberService: SMemberService,
    private authService: AuthService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.memberEditForm = this.formBuilder.group({
      id:[ '', [Validators.required] ],
      pw:[ '', [Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{5,19}$')] ],
      pw_check:[ '', [Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{5,19}$')] ],
      name:[ '', [Validators.required] ],
      hp1:[ '', [Validators.required] ],
      hp2:[ '', [Validators.required, Validators.pattern('^[0-9]{7,8}$')] ],
      email1:[ '', [Validators.required, Validators.pattern('^[a-zA-Z0-9._-]*$')] ],
      email2:[ '', [Validators.required, Validators.pattern('^[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$')] ],
      email3:[ '' ],
      customer_seq:[ '' ],
      customer_name:[ '' ],
      customer_type:[ '0001' ],
      customer_kind:[ '0002' ],
      business_no:[ '', [Validators.pattern('^[0-9]{10}$')] ],
      ceo: [''],
      customer_tel:[ '', Validators.pattern('^[0-9]{9,}$') ],
      taxinvoice_email1:[ '', [Validators.pattern('^[a-zA-Z0-9._-]*$')] ],
      taxinvoice_email2:[ '', [Validators.pattern('^[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$')] ],
      taxinvoice_email3:[ '' ],
      business_type:[ '' ],
      business_item:[ '' ],
      customer_zipcode:[ '' ],
      customer_address:[ '' ],
      customer_address_detail:[ '' ],
    });

    this.memberEditForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.memberEditForm, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;
    });

    // 회원/비회원 체크
    if( this.memberInfo.level == null || this.memberInfo.level < 1 ) {
      this.router.navigate(['/mypage/dashboard']);
    }

    // 비밀번호 재확인 체크
    if( this.sMemberService.pw_confirm != true ) {
      this.router.navigate(['/mypage/pwconfirm']);
    }
  }

  ngAfterViewInit() {
    this.getMemberDetail();
  }

  ngDoCheck() {
    // 비밀번호 입력시 비밀번호 확인 필수로 입력 처리
    if( this.memberEditForm.get('pw').value == "" ) {
      this.memberEditForm.get('pw_check').clearValidators();
    } else {
      this.memberEditForm.get('pw_check').setValidators(Validators.required);
    }
  }

  /*******************************************************************************
    설  명 : 사용자 정보 가져오기
  *******************************************************************************/
  getMemberDetail() {
    this.sMemberService.getMemberDetail()
    .then(response => {
      if( response['ResultCode'] == true ) {
        let data = response['data'];

        data.hp1 = data.hp.substr(0,3);
        data.hp2 = data.hp.substr(3);

        if( data.email != null ) {
          let emailArr = data.email.split('@');
          data.email1 = emailArr[0];
          data.email2 = emailArr[1];
          data.email3 = "";

          this.email_array.forEach( item => {
            if( item == emailArr[1] ) {
              data.taxinvoice_email3 = emailArr[1];
              return;
            }
          });
        }

        if( data.taxinvoice_email != null ) {
          let taxinvoiceEmailArr = data.taxinvoice_email.split('@');
          data.taxinvoice_email1 = taxinvoiceEmailArr[0];
          data.taxinvoice_email2 = taxinvoiceEmailArr[1];
          data.taxinvoice_email3 = "";

          this.email_array.forEach( item => {
            if( item == taxinvoiceEmailArr[1] ) {
              data.taxinvoice_email3 = taxinvoiceEmailArr[1];
              return;
            }
          });
        }

        this.memberEditForm.patchValue( data );
      }
    })
    .catch(response => {
      this.toastrService.error('사용자 정보를 가져오는데 실패하였습니다.', '');
      this.utilService.handleFormSubmitError(response, null, null);
    });
  }

  /*******************************************************************************
    설  명 : 이메일 주소 변경시 자동입력
  *******************************************************************************/
  setEmailChange(event, element) {
    this.memberEditForm.get( element ).setValue( event.target.value );
    if( event.target.value == "" ) {
      if( element == 'email2' ) this.inputEmail2.nativeElement.focus();
      if( element == 'taxinvoice_email2' ) this.inputTaxinvoiceEmail2.nativeElement.focus();
    }
  }

  /*******************************************************************************
    설  명 : 다음 주소 처리
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.memberEditForm.patchValue({
      customer_zipcode: data.zip,
      customer_address: data.addr
    });

    $("#customer_address_detail").focus();
  }

  /*******************************************************************************
    설  명 : 회사검색
  *******************************************************************************/
  searchCustomer() {
    const modalRef = this.modalService.open(SCompanySearchComponent, options);

    //modalRef.componentInstance.selectedItem = item;

    modalRef.componentInstance.emitData.subscribe(($e) => {
      this.memberEditForm.get('customer_seq').setValue( $e.seq );
      this.memberEditForm.get('customer_name').setValue( $e.name );
      this.memberEditForm.get('customer_type').setValue( $e.type );
      this.memberEditForm.get('customer_kind').setValue( $e.kind );
      this.memberEditForm.get('business_no').setValue( $e.business_no.replace(/-/g,'') );
      this.memberEditForm.get('ceo').setValue( $e.ceo );
      this.memberEditForm.get('customer_tel').setValue( $e.tel.replace(/-/g,'') );

      let taxinvoiceEmail = $e.taxinvoice_email.split('@');
      this.memberEditForm.get('taxinvoice_email1').setValue( taxinvoiceEmail[0] );
      this.memberEditForm.get('taxinvoice_email2').setValue( taxinvoiceEmail[1] );

      this.email_array.forEach( item => {
        if( item == taxinvoiceEmail[1] ) {
          this.memberEditForm.get('taxinvoice_email3').setValue( taxinvoiceEmail[1] );
          return;
        }
      });

      this.memberEditForm.get('business_type').setValue( $e.business_type );
      this.memberEditForm.get('business_item').setValue( $e.business_item );
      this.memberEditForm.get('customer_zipcode').setValue( $e.zipcode );
      this.memberEditForm.get('customer_address').setValue( $e.address );
      this.memberEditForm.get('customer_address_detail').setValue( $e.address_detail );
    });

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 회사정보 입력폼 초기화
  *******************************************************************************/
  initCustomer() {
    this.memberEditForm.get('customer_seq').setValue( "" );
    this.memberEditForm.get('customer_name').setValue( "" );
    this.memberEditForm.get('customer_type').setValue( "" );
    this.memberEditForm.get('customer_kind').setValue( "" );
    this.memberEditForm.get('business_no').setValue( "" );
    this.memberEditForm.get('ceo').setValue( '' );
    this.memberEditForm.get('customer_tel').setValue( "" );
    this.memberEditForm.get('taxinvoice_email1').setValue( "" );
    this.memberEditForm.get('taxinvoice_email2').setValue( "" );
    this.memberEditForm.get('business_type').setValue( "" );
    this.memberEditForm.get('business_item').setValue( "" );
    this.memberEditForm.get('customer_zipcode').setValue( "" );
    this.memberEditForm.get('customer_address').setValue( "" );
    this.memberEditForm.get('customer_address_detail').setValue( "" );
  }

  /*******************************************************************************
    설  명 : 회원정보수정 처리
  *******************************************************************************/
  public submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.memberEditForm, this.formErrors);

    if( this.memberEditForm.valid ) {
      this.sMemberService.setMemberSave( this.memberEditForm ).then(response => {
        if( response['ResultCode'] == true ) {
          this.toastrService.success(response['ResultMessage'], '회원정보수정 성공');
          this.router.navigate(['/mypage/dashboard']);
        } else {
          this.toastrService.error(response['ResultMessage'], '회원정보수정 실패');
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.memberEditForm, this.formErrors);
      });
    }
  }

}
