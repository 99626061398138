import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';

import { SOrderService } from '@shop/service/order.service';
import { SCartService } from '@shop/service/cart.service';
import { SCategoryService } from '@shop/service/category.service';
import { SMemberService } from '@shop/service/member.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { SEstimateComponent } from '@shop/page/member/mypage/estimate-list/estimate/estimate.component';
import { MuseGuideComponent } from '@mobile/page/Information/use-guide/use-guide.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const options1: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg-1000',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-mgnb',
  templateUrl: './gnb.component.html',
  styleUrls: ['./gnb.component.scss']
})
export class MgnbComponent implements OnInit {

  @Input() isProject: boolean = false;

  isLogin$: Observable<boolean>;

  public isMGnbOpen: boolean = false;      // 모바일 Gnb 창 열림 상태

  public memberInfo: any = {};

  public categoryList: any = [];
  public params: any = {
    categoryCode: ''
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sCategoryService: SCategoryService,
    private modalService: NgbModal,
    private memberService: SMemberService,
    private sOrderService: SOrderService,
    private sCartService: SCartService,
    private authService: AuthService,
    private globals: Globals
  ) {
    this.isLogin$ = this.authService.getIsLogin;
  }

  ngOnInit() {
    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.categoryCode = ( typeof params.categoryCode == 'undefined' || params.categoryCode == '' ) ? '' : params.categoryCode;
    });

    this.sCartService.mGnbOpen.subscribe((data) => {
      this.isMGnbOpen = data;
    });

    this.getCategoryList();
  }

  /*******************************************************************************
    설  명 : 페이지로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goBoard( board_id ) {
    this.closeMGnb();

    this.router.navigate(
    ['/mobile/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: {board_id: board_id, type: ''},
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 로그아웃
  *******************************************************************************/
  setLogout() {
    this.memberService.setLogout().then( async response => {
      if( response.ResultCode == true ) {
        this.toastrService.success(response.ResultMessage, '');

        await this.authService.logout('shop');
        await this.sCartService.quickOpen.next( false );

        this.router.navigate(['/mobile/main']);
      } else {
        this.toastrService.error(response.ResultMessage, '');
      }
    })
    .catch(response => {
    });
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
  *******************************************************************************/
  async getCategoryList() {
    await this.sCategoryService.getCategoryList().then( response => {
      if( response.ResultCode ) {
        this.categoryList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : 페이지 리스트 새로고침
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadList( row: any ) {
    this.params.categoryCode = row.category_code;

    this.router.navigate(
    ['/mobile/main'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 견적요청
  *******************************************************************************/
  writeEstimate() {
    /*
    const modalRef = this.modalService.open(SEstimateComponent, options);

    modalRef.componentInstance.boardId = 'estimate';

    modalRef.result.then((result) => {
      this.router.navigate( ['/mobile/member/mypage/estimate'] );
    }, (reason) => {
    });
    */
    /*
    this.router.navigate(
      ['/mobile/member/mypage/estimate/request'],
      {
        relativeTo: this.activatedRoute,
        queryParams: [],
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });    
    */
    this.toastrService.warning( '견적문의는 PC에서 이용하시기 바랍니다.', '' );
  }

  /*******************************************************************************
    설  명 : 이용안내
  *******************************************************************************/
  useGuide() {
    const modalRef = this.modalService.open(MuseGuideComponent, options1);

    modalRef.result.then((result) => {

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 빠른주문 창 슬라이드 닫기
  *******************************************************************************/
  closeMGnb() {
    this.sCartService.setMGnbOpen( false );
    this.sCartService.setMask( false );
  }

}
