import { Component, OnInit } from '@angular/core';

declare global {
  interface Window {
    INIStdPay: any;
  }
}

@Component({
  selector: 'app-inistdpay-popup',
  templateUrl: './inistdpay-popup.component.html',
  styleUrls: ['./inistdpay-popup.component.scss']
})
export class SInistdpayPopupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

	ngAfterViewInit() {

	}

}
