/*******************************************************************************
  설명 : 서버와 통신
  작성일 : 2019-07-07
  작성자 : 송영석
*******************************************************************************/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';

import { config } from './config.service';
import { Globals } from './globals.service';
import { UtilService } from './util.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;',
  })
};

@Injectable({
  providedIn: 'root'
})

export class RestfulService {

  // Restful 서비스를 담아둔다.
  public queue: BehaviorSubject<[]> = new BehaviorSubject([]);

  public indicator: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public indicator$ = this.indicator.asObservable();

  /*******************************************************************************
    설명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public globals: Globals,
    public http: HttpClient,
    public utilService: UtilService,
  ) {
    this.queue.subscribe( res => {
      if( res.length < 1 ) {
        setTimeout( () => {
          this.indicator.next( false );
        });
      }
    });
  }

  /*******************************************************************************
    설명 : 데이터 분리
    입력값 : response 데이터
    리턴값 : array
  *******************************************************************************/
  public extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  /*******************************************************************************
    설명 : http 에러 처리
    입력값 : response 데이터
    리턴값 : reject message
  *******************************************************************************/
  public handleErrorPromise (error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  /*******************************************************************************
    설명 : http get
    입력값 : uri = {program, service, version, action}
             params = {}
    리턴값 : promise
  *******************************************************************************/
  public get( uri:any, params:any ): Promise<any> {
    let url = this.globals.apiBaseUrl;

    if( ! uri.program ) params.program = 'admin';
    else params.program = uri.program;

    params.service = uri.service;
    params.version = uri.version;
    params.action = uri.action;
    params.token = this.globals.token;

    let input = '?' + $.param( params );

    // indicator 용 처리
    this.push( params );

    return this.http.get( url + input, httpOptions).toPromise().then( response => {
      // 로그아웃이 아닌 경우 세션 연장
      if( params.action != 'setLogout' ) {
        // 사용자 세션 연장 처리
        if( response.hasOwnProperty( 'token' ) ) {
          if( response['token'] ) {
            if( response['token'] !== this.globals.token ) {
              this.globals.setToken( response['token'] );
            }
          }
        }
      }

      // indicator 용 처리
      this.pop( params );

      return response;
    });
  }

  /*******************************************************************************
    설명 : http post
    입력값 : uri = {program, service, version, action}
             params = {}
    리턴값 : promise
  *******************************************************************************/
  public post( uri:any, params:any): Promise<any> {
    let url = this.globals.apiBaseUrl;

    if( ! uri.program ) params.program = 'admin';
    else params.program = uri.program;

    params.service = uri.service;
    params.version = uri.version;
    params.action = uri.action;
    params.token = this.globals.token;

    let input = $.param( params );

    // indicator 용 처리
    this.push( params );

    let promise = this.http.post( url, input, httpOptions).toPromise();

    promise.then(( response : Response ) => {
      /*
      if( response.hasOwnProperty( 'token' ) ) {
        if( response['token'] ) {
          if( this.globals.token == '' || this.globals.token == null ) {
            this.globals.setToken( response['token'] );
          }
        }
      }
      */

      // 사용자 세션 연장 처리
      if( response !== null && response.hasOwnProperty( 'token' ) ) {
        if( response['token'] ) {
          if( response['token'] !== this.globals.token ) {
            this.globals.setToken( response['token'] );
          }
        }
      }

      // indicator 용 처리
      this.pop( params );
    }).catch( error => {
      console.log( error );
      this.pop( params );
    });

    return promise;
  }

  /*******************************************************************************
    설명 : token key refresh
    입력값 : 없음
    리턴값 : true / false
  *******************************************************************************/
  public refreshToken() {

  }

  /*******************************************************************************
    설명 : indicator 표시 용 push
    입력값 : params
    리턴값 : 없음
  *******************************************************************************/
  private push( params: any ) {
    let data: any = this.queue.getValue();

    if( data.length < 1 ) {
      setTimeout( () => {
        this.indicator.next( true );
      });
    }

    data.push( params );

    this.queue.next( data );
  }

  /*******************************************************************************
    설명 : indicator 표시 용 pop
    입력값 : params
    리턴값 : 없음
  *******************************************************************************/
  private pop( params: any ) {
    let tmp: any = this.queue.getValue();

    let index = tmp.findIndex( data => {
      return ( data.program == params.program && data.service == params.service && data.version == params.version && data.action == params.action ) ? true : false;
    });

    tmp.splice( index, 1 );

    this.queue.next( tmp );
  }
}