import { Component, OnInit, Input, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions,
  NgbInputDatepicker, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-muse-guide',
  templateUrl: './use-guide.component.html',
  styleUrls: ['./use-guide.component.scss']
})
export class MuseGuideComponent implements OnInit {

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }

  close() {
    this.activeModal.close();
  }  
}