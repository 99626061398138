import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SProductService {

  public selectSeq: number;

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 상품 리스트 가져오기
  getProductList( search ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getProductList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상품 트리구조 리스트 가져오기
  getProductTreeList( projectSeq ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getProductTreeList'
      }, {
				project_seq: projectSeq
			}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상품의 관련상품 리스트 가져오기
  getProductTagList( search ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getProductTagList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상품정보 가져오기
  getProductDetail( search ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'product',
        version: '1.0',
        action: 'getProductDetail'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
