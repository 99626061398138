import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { config } from '@app/service/config.service';
import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';
import { SOrderService } from '@shop/service/order.service';
import { SCartService } from '@shop/service/cart.service';
import { DiscountService } from '@app/service/discount.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { SEstimateComponent } from '@shop/page/member/mypage/estimate-list/estimate/estimate.component';
import { SCCartModalComponent } from '@shop/components/cart-modal/cart-modal.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionsSM: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-quick-order',
  templateUrl: './quick-order.component.html',
  styleUrls: ['./quick-order.component.scss']
})
export class SQuickOrderComponent implements OnInit {

  @Input() isQuickOpen: any;
  // @Input() quickOrderList: any;
  @Input() isProject: boolean = false;

  @Input() set quickOrderInput(value: any) {
    this.quickOrderList = value;

    this.setTotalPrice();
  }

  public quickOrderList: any = [];

  isLogIn$: Observable<boolean>;
  isLogIn: boolean;

  public baseURL: string = config.baseUrl;

  public totalPrice: number = 0;

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sCartService: SCartService,
    private sOrderService: SOrderService,
    private modalService: NgbModal,
    public authService: AuthService,
    private discountService: DiscountService,
    private globals: Globals,
  ) {
    this.isLogIn$ = this.authService.getIsLogin;

    this.isLogIn$.subscribe( login => {
      this.isLogIn = login;
    });

  }

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
  }

  /*******************************************************************************
    설  명 : 빠른주문창 슬라이드
  *******************************************************************************/
  slideQuickOrder() {
    if($("#contents-area").hasClass("slide")) {
      this.sCartService.quickOpen.next( false );
    } else {
      this.sCartService.quickOpen.next( true );
    }
  }

  /*******************************************************************************
    설  명 : 화면 맨 위로 이동
  *******************************************************************************/
  top() {
    $('html, body').animate({
       scrollTop : 0
      }, 0);
  }

  /*******************************************************************************
    설  명 : 빠른주문 리스트 전체 삭제
  *******************************************************************************/
  deleteAllQuickOrder() {
    this.sCartService.setDeleteCart();

    //this.setTotalPrice();
  }

  /*******************************************************************************
    설  명 : 빠른주문 리스트 선택 삭제
  *******************************************************************************/
  deleteQuickOrder( index: number ) {
    this.sCartService.setDeleteRowCart( this.quickOrderList[index], index );

    //this.setTotalPrice();
  }

  /*******************************************************************************
    설  명 : 수량 변경 시
  *******************************************************************************/
  changeCount( index: number, event ) {
    this.sCartService.setEditCart( this.quickOrderList[index], index, event.target.value );

    //this.setTotalPrice();
  }

  /*******************************************************************************
    설  명 : 전체 주문금액 구하기
  *******************************************************************************/
  setTotalPrice() {
    //setTimeout(() => {
      let total = 0;

      if( this.quickOrderList.length > 0 ) {
        this.quickOrderList.forEach(obj => {
          //let amt = obj.sale_amt * obj.count;
          //let amt = obj.discount.total_amt * obj.count;
          total += parseInt(obj.discount.total_amt);
        });
      }

      this.totalPrice = total;
    //});
  }

  /*******************************************************************************
    설  명 : 견적요청
  *******************************************************************************/
  writeEstimate() {
    /*
    const modalRef = this.modalService.open(SEstimateComponent, options);

    const params = {
      contents: ''
    };

    this.quickOrderList.forEach(obj => {
      let row = obj.name + " " + obj.count + "개";
      params.contents = params.contents + row + "\r";
    });

    modalRef.componentInstance.boardId = 'estimate';
    modalRef.componentInstance.quickData = params;

    modalRef.result.then((result) => {
      this.router.navigate( ['/mypage/estimate'] );
    }, (reason) => {
    });
    */
    // console.log(this.quickOrderList);
    const params: any = [];
    this.quickOrderList.forEach(item => {
      params.push({
        parent_category_code: item.parent_category_code,
        product_seq: item.sp_seq,
        count: item.count,
      });
    });

    this.globals.save( 'thefirstpro_quickOrderList', params );

    this.router.navigate(
      ['/mypage/estimate/request'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {},
        queryParamsHandling: '', // remove to replace all query params by provided
      });
  }

  /*******************************************************************************
    설  명 : 주문하기
  *******************************************************************************/
  orderLoginCheck( type ) {
    /*
    const modalRef = this.modalService.open(SCCartModalComponent, optionsSM);

    modalRef.result.then((result) => {
      console.log( result );
    }, (reason) => {
      console.log( reason );
    });

    if( confirm("비회원으로 주문하시겠습니까?\r취소를 누르면 로그인 페이지로 이동합니다.") ) {

      this.router.navigate(
      ['/member/non'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {type: type},
        queryParamsHandling: '', // remove to replace all query params by provided
      });
    } else {
      this.router.navigate( ['/login'] );
    }
		*/
  }

  /*******************************************************************************
    설  명 : 주문하기
  *******************************************************************************/
  setOrder() {
    let data: any = [];

    for( let item of this.quickOrderList ) {
      if( item.stock_yn == false ) {
        this.toastrService.error( '품절인 상품은 주문하실 수 없습니다.', item.product_name);
        return false;
      }

      if( item.min_qty != '0' ) {
        if( item.count < parseInt( item.min_qty ) ) {
          this.toastrService.error( item.name + ' : 주문 수량을 최소 수량(' + item.min_qty + ') 보다 크게 입력하십시오.', '주문하기');
          return false;
        }
      }

      data.push({
        //seq: item.seq,
        //count: item.count
        seq: item.sp_seq,
        cart_seq: item.cart_seq,
        count: item.qty
      });
    }

		// 빠른주문창 닫기
    // this.sOrderService.setQuickOpen( false );
    this.sCartService.quickOpen.next( false );

    data = JSON.stringify( data );

    this.router.navigate(
    ['/order'],
    {
      relativeTo: this.activatedRoute,
      queryParams: {
        data: encodeURIComponent( data )
      },
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

}
