import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SMemberService {

	public findResult: any = '';
	public pw_confirm: boolean = false;

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 회원 로그인
  setLogin( form: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'setLogin'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 비회원 로그인
  setLoginNonMember( form: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'setLoginNonMember'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원번호로 로그인
  setLoginMemberNo( memberNo: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'setLoginMemberNo'
      }, {
				mem_no: memberNo
			}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원 로그아웃
  setLogout(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'setLogout'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 패스워드 검사
  checkPwConfirm( form: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'checkPwConfirm'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 중복아이디 검사
  checkIdDuplicate( id: string ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'checkIdDuplicate'
      }, {
        id: id
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회사 검색
  getSearchCustomer( form: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getSearchCustomer'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원정보 가져오기
  getMemberDetail(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getMemberDetail'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 아이디 찾기
  setFindId( form: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'setFindId'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 비밀번호 찾기
  setFindPw( form: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'setFindPw'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 비회원 비밀번호 찾기
  setOrderFindPw( form: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'setOrderFindPw'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원정보 저장
  setMemberSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'setMemberSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 비회원정보 저장
  setNonMemberSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'setNonMemberSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 마이페이지 총 이용금액 가져오기
  getTotalAmtUsed(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getTotalAmtUsed'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 마이페이지 진행중인 주문 카운트 가져오기
  getMypageOrderCount(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'member',
        version: '1.0',
        action: 'getMypageOrderCount'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
