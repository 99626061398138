import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';

import { SBoardService } from '@shop/service/board.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mquestion-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class MquestionAddComponent implements OnInit {

  @Input() boardId: any;
  @Input() groupTab: any;
  @Input() rowData: any;
  
  errorResponse: ApiResponse;
  public boardWriteForm: FormGroup;
  public formErrors = {
    'board_seq': '',
    'board_id': '',
    'subject': '',
    'group_id': '',
    'contents': '',
  };

  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    public sBoardService: SBoardService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.boardWriteForm = this.formBuilder.group({
      board_seq: [""],
      board_id: ["", [Validators.required]],
      subject: ["", [Validators.required]],
      group_id: ["", [Validators.required]],
      contents: ["", [Validators.required]],
    });

    this.boardWriteForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.boardWriteForm, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
    this.boardWriteForm.get('board_id').setValue( this.boardId );

    if( this.rowData != null && this.rowData != '' ) {
      this.boardWriteForm.patchValue( this.rowData );
    }
  }

  /*******************************************************************************
    설  명 : 문의하기 처리
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.boardWriteForm, this.formErrors);

    if( this.boardWriteForm.valid ) {
      this.sBoardService.setWrite( this.boardWriteForm )
      .then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }

        this.activeModal.close();
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.boardWriteForm, this.formErrors);
      });
    }
  }

}
