import { Component, OnInit, Input, Injectable } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions,
  NgbInputDatepicker, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig
} from '@ng-bootstrap/ng-bootstrap';

import { config } from '@app/service/config.service';
import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { SCommonService } from '@shop/service/common.service';
import { SProductService } from '@shop/service/product.service';
import { SProjectService } from '@shop/service/project.service';
import { SOrderService } from '@shop/service/order.service';
import { SMemberService } from '@shop/service/member.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

const I18N_VALUES = {
  ko: {
    weekdays: ['월', '화', '수', '목', '금', '토', '일'],
    months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  }
};

@Injectable()
export class I18n {
  language = 'ko';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component( {
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  providers: [
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    NgbDatepickerConfig
  ]
} )
export class SOrderComponent implements OnInit {

  daumAddressOptions: any = {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  public isPay: boolean = false;

  public baseURL: string = config.baseUrl;

  public isLogin: boolean;

  public productList: any = [];
  public projectList: any = [];
  public projectTypeList: any = [];

  public projectSelect2Data: any = [];

  public projectGroup: any = '';

  public memberInfo: any = {};
  public companyInfo: any = {};

  errorResponse: ApiResponse;
  public form: FormGroup;
  public formErrors: any = {};

  private set: any = {
    install_date: '',
    install_time: '14:00',
    person_name: '',
    person_hp1: '010',
    person_hp2: '',
    person_email1: '',
    person_email2: '',
    place: '',
    total_amt: 0,
    open: true,
    product: []
  };

  public projectSeq: any = '';

  private params: any = {
    data: ''
  };

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private sProductService: SProductService,
    private sProjectService: SProjectService,
    private sOrderService: SOrderService,
    private sMemberService: SMemberService,
    private sCommonService: SCommonService,
    private authService: AuthService,
    private cookieService: CookieService,
    private calendar: NgbCalendar,
    private ngbDatepickerConfig: NgbDatepickerConfig,
 ) {
    ngbDatepickerConfig.displayMonths = 1;
    ngbDatepickerConfig.firstDayOfWeek = 7; // 일요일 먼저

    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 폼 생성
  *******************************************************************************/
  buildForm(): void {
    const todayModel: NgbDateStruct = this.utilService.getDate('');

    this.form = this.formBuilder.group( {
      name:[ '', [] ],
      pw:[ '', [] ],
      pw_check:[ '', [] ],
      email:[ '', [] ],
      hp1:[ '010', [] ],
      hp2:[ '', [] ],
      project_group: [ "", [Validators.required] ],
      project_seq: [ "", [] ],
      project_name: [ "", [Validators.required] ],
      place: [ "", [Validators.required] ],
      status: [ "1000", [Validators.required] ],
      rental_sdate: [ todayModel, [Validators.required] ],
      rental_edate: [ todayModel, [Validators.required] ],
      zipcode: [ "", [] ],
      address: [ "", [] ],
      address_detail: [ "", [] ],
      memo: [ "", [Validators.maxLength(255)] ],
      amt: [0, [Validators.required] ],
      set: [[], []],
      active: [0, []],
    } );
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  async ngOnInit() {
    this.authService.getIsLogin.subscribe(data => {
      this.isLogin = data;
    } );

    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;

      if (typeof this.memberInfo.hp != 'undefined') {
        const hp: any = this.memberInfo.hp.replace('-','');
        this.set.person_name = this.memberInfo.name;
        this.set.person_hp1 = this.memberInfo.hp.substring(0,3);
        this.set.person_hp2 = hp.replace('010','');
        this.set.person_email1 = this.memberInfo.email;
      }
    } );

    if (!this.isLogin) {
      this.form.get('name').setValidators( [Validators.required, Validators.pattern('^[가-힣a-zA-Z]*$') ] );
      this.form.get('name').updateValueAndValidity();
      this.form.get('pw').setValidators( [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{5,19}$') ] );
      this.form.get('pw').updateValueAndValidity();
      this.form.get('pw_check').setValidators( [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{5,19}$') ] );
      this.form.get('pw_check').updateValueAndValidity();
      this.form.get('email').setValidators( [Validators.required, Validators.email] );
      this.form.get('email').updateValueAndValidity();
      this.form.get('hp2').setValidators( [Validators.required, Validators.pattern('^[0-9]{7,8}$') ] );
      this.form.get('hp2').updateValueAndValidity();
    }

    // 기본 세트 상품 추가
    this.insertSet();

    // 프로젝트 번호 가져오기
    this.sProjectService.getProjectSeq.subscribe(data => {
      this.projectSeq = data;
    } );

    // 파라미터 가져오기
    this.activatedRoute.queryParams.subscribe(async params => {
      if (typeof params.data !== 'undefined' && params.data.length > 0) {
        const data: any = (typeof params.data == 'undefined' || params.data == '') ? '' : decodeURIComponent(params.data);
        this.params.data = JSON.parse(data);
      }
      // 상품 트리 가져오기
      await this.getProductTreeList();
    } );

    // 회사정보 가져오기
    await this.getCompanyInfo();

    // 프로젝트 타입 가져오기
    await this.getCommonListCode();

    // 프로젝트 리스트 가져오기
    this.sProjectService.getProjectList().then(response => {
      if (response.ResultCode) {
        this.projectList = response.data;

        this.projectSelect2Data = response.data.reduce( (result, row) => {
          if (row.type_seq == this.projectTypeList[0].seq) {
            result.push( {
              id: row.seq,
              text: row.name
            } );
          }

          return result;
        }, [ {
          id: 0,
          text: "전시/행사명 선택",
        } ] );
      } else {
        this.toastrService.error(response.ResultMessage, '');
      }
    } );

    // 폼 입력값 변경 시 체크
    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    } );
  }

  // depth2 펼치기
  goods_expand1(event) {

    let that = $(event.target);
    let findrowA  = that.closest("a");    //  클릭한 행의 A

    let $element = $(".depth2");
    let element: Element = $element.get(0);

    if (!(findrowA.hasClass("expand") ) ) {
      $(".depth1 > li > a").removeClass("expand");
      $element.addClass("hide");

      findrowA.addClass("expand");
      findrowA.parent().find($element).removeClass("hide");
    } else {
      findrowA.removeClass("expand");
      findrowA.parent().find($element).addClass("hide");
    }

  }

  // depth3 펼치기
  goods_expand2(event) {

    let that = $(event.target);
    let findrowA  = that.closest("a");    //  클릭한 행의 A

    let $element = $(".depth3");
    let element: Element = $element.get(0);

    if (!(findrowA.hasClass("expand") ) ) {
      $(".depth2 > li > a").removeClass("expand");
      $element.addClass("hide");

      findrowA.addClass("expand");
      findrowA.parent().find($element).removeClass("hide");
    } else {
      findrowA.removeClass("expand");
      findrowA.parent().find($element).addClass("hide");
    }

  }

  /*******************************************************************************
    설  명 : 상품 리스트 가져오기
  *******************************************************************************/
  async getProductTreeList() {
    await this.sProductService.getProductTreeList(this.projectSeq).then(response => {
      if (response.ResultCode) {
        this.productList = response.data;

        // 파라미터로 넘겨진 상품 추가
        this.activatedRoute.queryParams.subscribe(params => {
          if (typeof params.data !== 'undefined' && params.data.length > 0) {
            let data: any = decodeURIComponent(params.data);
            try {
              data = JSON.parse(data);

              if (data.length > 0) {
                let selectedItem: any;
                for(const item of data) {
                  selectedItem = this.getProductItem(this.productList, item.seq);

                  if (selectedItem !== false) {
                    // 수량 설정
                    selectedItem.qty = item.count;

										if (selectedItem.pay_unit_code == '0002') selectedItem.rental_daycount = 1;

                    // 선택된 상품 추가
                    this.form.controls.set.value[this.form.controls.active.value].product.push(selectedItem);

                    // 상품 금액 계산
                    this.setCalcAmt();
                  }
                }
              }
            } catch(e) {
              this.toastrService.error( '정상적인 경로로 진행하시기 바랍니다.', '주문하기');

              this.router.navigate(
              ['/main'],
              {
                relativeTo: this.activatedRoute,
                queryParams: {},
                queryParamsHandling: '', // remove to replace all query params by provided
              } );
            }
          }
        } );

      } else {
        this.toastrService.error(response.ResultMessage, '');
      }
    } );
  }

  /*******************************************************************************
    설  명 : 제품 트리에서 제품 찾기
  *******************************************************************************/
  getProductItem(obj: any, seq: any) {
    if (obj === false) return false;

    for(const item of obj) {
      // 카테고리일 경우
      if (typeof item.category_code !== 'undefined') {
        if (typeof item.children == 'undefined') return false;

        const result: any = this.getProductItem(item.children, seq);
        if (result !== false) return result;

      // 상품일 경우
      } else {
        if (item.seq == seq) return item;
      }
    }

    return false;
  }

  /*******************************************************************************
    설  명 : 회사 정보 가져오기
  *******************************************************************************/
 async getCompanyInfo() {
  await this.sCommonService.getCompanyInfo().then(response => {
    if (response.ResultCode) {
      this.companyInfo = response.data;

      if (this.memberInfo.id == 'admin' || this.memberInfo.id == 'sys1000') {
        this.companyInfo.order_yn = '1';
      }
    } else {
      this.toastrService.error(response.ResultMessage, '');
    }
  } );
}

  /*******************************************************************************
    설  명 : 프로젝트 구분 리스트 가져오기
  *******************************************************************************/
  async getCommonListCode() {
    await this.sCommonService.getCommonListCode('PJT').then(response => {
      if (response.ResultCode) {
        this.projectTypeList = response.data;

        this.form.patchValue({
          project_group: this.projectTypeList[0].seq
        });
      } else {
        this.toastrService.error(response.ResultMessage, '');
      }
    } );
  }

  /*******************************************************************************
    설  명 : 프로젝트 select2 선택 시 처리
    입력값 : value
    리턴값 : 없음
  *******************************************************************************/
  projectSelect2Change(selected: any) {
    let project: any = this.projectList.find(project => (selected.id > 0) && (project.seq === selected.id) );

    if (typeof project !== 'undefined') {
      let start_date: NgbDateStruct;
      let end_date: NgbDateStruct;

      start_date = {
        year: parseInt(project.start_date.substr(0, 4) ),
        month: parseInt(project.start_date.substr(5, 2) ),
        day: parseInt(project.start_date.substr(8, 2) )
      };

      end_date = {
        year: parseInt(project.end_date.substr(0, 4) ),
        month: parseInt(project.end_date.substr(5, 2) ),
        day: parseInt(project.end_date.substr(8, 2) )
      };

      this.form.patchValue( {
        project_seq: project.seq,
        rental_sdate: start_date,
        rental_edate: end_date,
        zipcode: project.zipcode,
        address: project.address,
        address_detail: project.address_detail
      } );

      this.form.get('project_seq').setValidators( [Validators.required] );
      this.form.get('project_seq').updateValueAndValidity();
      this.form.get('project_name').clearValidators();
      this.form.get('project_name').updateValueAndValidity();
      this.form.get('place').clearValidators();
      this.form.get('place').updateValueAndValidity();
    } else {
      // 전시/행사명 입력폼 필수값으로
      this.form.patchValue( {
        project_seq: 0,
        project_name: "",
        rental_sdate: "",
        rental_edate: "",
        zipcode: "",
        address: "",
        address_detail: "",
      } );

      this.form.get('project_seq').clearValidators();
      this.form.get('project_seq').updateValueAndValidity();
      this.form.get('project_name').setValidators( [Validators.required] );
      this.form.get('project_name').updateValueAndValidity();
      this.form.get('place').setValidators( [Validators.required] );
      this.form.get('place').updateValueAndValidity();
    }
  }

  /*******************************************************************************
    설  명 : 세트 상품 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  insertSet() {
    let set: any = [];
    set = this.form.controls.set.value;

    const tmp: any = {
      install_date: '',
      install_time: '14:00',
      person_name: this.set.person_name,
      person_hp1: this.set.person_hp1,
      person_hp2: this.set.person_hp2,
      person_email1: this.set.person_email1,
      person_email2: '',
      place: '',
      total_amt: 0,
      open: true,
      product: []
    };

    set.push(tmp);

    this.form.patchValue( {
      set: set
    } );

  }

  /*******************************************************************************
    설  명 : 세트 상품 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteSet(index) {
    const set: any = this.form.controls.set.value;

    set.splice(index, 1);

    this.form.patchValue({ set: set });
  }

  /*******************************************************************************
    설  명 : 세트 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSetList() {
    let set: any = [];

    for(const item of this.form.controls.set.value) {
      set.push(item);
    }
    return set;
  }

  /*******************************************************************************
    설  명 : 세트 담긴 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSetProductList(item) {
    if (item.product.length < 1) return [];

    let product: any = [];

    for(const subitem of item.product) {
      product.push(subitem);
    }
    return product;
  }

  /*******************************************************************************
    설  명 : 상품 선택 시 현재 선택 된 세트 상품에 추가
  *******************************************************************************/
  selectProduct(product) {

    if (product.min_qty != '0') product.qty = parseInt(product.min_qty);
    else product.qty = 1;

		if (product.pay_unit_code == '0002') product.rental_daycount = 1;

    this.form.controls.set.value[this.form.controls.active.value].product.push(product);

    this.setCalcAmt();
  }

  /*******************************************************************************
    설  명 : 수량 변경 시
  *******************************************************************************/
  changeCount(product: any, $event: any, boothIndex: number, productIndex: number) {
    if (product.min_qty != '0') {
      if ($event.target.value < product.min_qty) {
        $event.target.value = product.min_qty;
        return false;
      }
    } else {
      // product.qty = $event.target.value;

      let row: any = JSON.parse(JSON.stringify(product));
      row.qty = $event.target.value;

      let set: any = this.form.controls.set.value;
      set[ boothIndex ].product[ productIndex ] = row;

      this.setCalcAmt();
    }
  }
  
  /*******************************************************************************
    설  명 : 대여일수 변경 시
  *******************************************************************************/
  changeDaycount(product, $event) {
    product.rental_daycount = $event.target.value;

    this.setCalcAmt();
  }

  /*******************************************************************************
    설  명 : 전체 금액 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCalcAmt() {
    const item: any = this.form.controls.set.value;
    let total_amt: number = 0;

    for(const subItem of item) {
      let sub_total_amt: number = 0;

      for(const product of subItem.product) {
				if (product.pay_unit_code == '0002')
	        sub_total_amt += (product.total_amt * product.qty * product.rental_daycount);
				else
					sub_total_amt += (product.total_amt * product.qty);
      }

      subItem.total_amt = sub_total_amt;

      total_amt += sub_total_amt;
    }

    item.total_amt = total_amt;

    this.form.patchValue( { set: item } );
		this.form.patchValue( { amt: total_amt } );
	}

  /*******************************************************************************
    설  명 : 주문정보(배송정보) 열기 / 닫기 처리
    입력값 : index
    리턴값 : 없음
  *******************************************************************************/
  openCheck(index) {
    const set = this.form.controls.set.value;

    set[index].open = ! set[index].open;

    this.form.patchValue( {set: set} );
  }

  /*******************************************************************************
    설  명 : 주문정보 상품리스트 선택 삭제
  *******************************************************************************/
  deleteProduct(index) {
    this.form.controls.set.value[this.form.controls.active.value].product.splice(index, 1);

    this.setCalcAmt();
  }

  /*******************************************************************************
    설  명 : 전시/기타 구분 클릭 시
  *******************************************************************************/
  changeProjectType(common_seq, common_code) {
    const tmp: any = [];
    this.projectList.forEach(obj => {
      if (obj.type_seq == common_seq) {
        tmp.push(obj);
      }
    } );

    this.projectSelect2Data = tmp;
    this.projectGroup = common_code;
  }

  /*******************************************************************************
    설  명 : 세트에 담당자정보 회원정보와 동일하게 처리
  *******************************************************************************/
  setMemberInfoCopy(index) {
    let newSet: any = [];
    newSet = this.form.controls.set.value;

    if( this.isLogin ) {
      this.set.person_name = this.memberInfo.name;
      this.set.person_email1 = this.memberInfo.email;
      if (typeof this.memberInfo.hp != 'undefined') {
        const hp: any = this.memberInfo.hp.replace('-', '');
        this.set.person_hp1 = hp.substring(0, 3);
        this.set.person_hp2 = hp.substring(3);
      }
    } else {
      newSet[ index ].person_name = this.form.controls.name.value;
      newSet[ index ].person_hp1 = this.form.controls.hp1.value;
      newSet[ index ].person_hp2 = this.form.controls.hp2.value;
      newSet[ index ].person_email1 = this.form.controls.email.value;
      newSet[ index ].person_email2 = '';
    }

    this.form.patchValue( {
      set: newSet
    } );
  }

  /*******************************************************************************
    설  명 : 다음 주소 처리
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue( {
      zipcode: data.zip,
      address: "(" + data.zip + ") " + data.addr
    } );

    $("#address_detail").focus();
  }

  /*******************************************************************************
    설  명 : 저장하기
  *******************************************************************************/
  public submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      for(const item of this.form.controls.set.value) {
        // 설치요청일자 필수 체크 해제 처리 11-14일 권도희 , 임동규 요청
//        if (item.install_date.length < 1) {
//          this.toastrService.error( '설치 요청일자를 선택하세요.', '주문하기');
//          return false;
        
        /* 2021-01-19 송영석 제외
           쇼핑몰 / 주문 등록시 설치시간 형식 변경 - 필수 X
        } else if (item.install_time.length < 1) {
          this.toastrService.error( '설치 요청시간을 선택하세요.', '주문하기');
          return false;
        */

 //       } else 
        if (item.place.length < 1) {
          this.toastrService.error( '설치 장소(부스번호)를 입력해주세요.', '주문하기');
          return false;

        } else if (item.person_name.length < 1) {
          this.toastrService.error( '인수 담당자를 입력해주세요.', '주문하기');
          return false;

        } else if (item.person_hp2.length < 1) {
          this.toastrService.error( '인수 담당자를 연락처를 입력해주세요.', '주문하기');
          return false;

        } else if (item.product.length < 1) {
          this.toastrService.error( '주문할 상품을 추가하세요.', '주문하기');
          return false;

        } else {
          // 상품 별 수량 체크
          for(const subItem of item.product) {
            if (subItem.qty < 1) {
              this.toastrService.error( '주문할 상품의 수량을 체크해 주세요.', '주문하기');
              return false;
            }
          }
        }
      }

      this.checkStatus();

      this.sOrderService.setOrderSave(this.form).then(response => {
        if (response['ResultCode'] == true) {

          let orderSeq = response.orderSeq;

          if( this.isPay === false )
            this.toastrService.success(response['ResultMessage'], '주문하기 성공');

          // 장바구니에서 주문한 목록 제거
          // 회원인 경우 DB에서 삭제
          if (this.isLogin) {

            const data: any = [];
            for(const item of this.params.data) {
              data.push( {
                cart_seq: item.cart_seq,
                isChecked: true
              } );
            }

            this.sOrderService.setDeleteSelectCart(data).then(response => {
              if (response['ResultCode'] == false) {
                this.toastrService.error(response['ResultMessage'], '장바구니 선택목록 삭제');
              }
            } )
            .catch(response => {
              this.toastrService.error(response, '장바구니 선택목록 삭제');
            } );

          // 비회원인 경우 쿠키에서 삭제
          } else {
            const cart: any = this.cookieService.get( 'thefirstpro_cart');
            const cartList: any = (this.cookieService.check( 'thefirstpro_cart') ) ? JSON.parse(cart) : [];

            for(const index in cartList) {
              // 삭제 처리
              for(const index2 in this.params.data) {
                if (cartList[index].seq == this.params.data[index2].seq) {
                  cartList.splice(index, 1);
                }
              }
            }

            this.cookieService.set( 'thefirstpro_cart', JSON.stringify(cartList) );
          }

          // 로그인 했을 경우 바로 이동 아니면 로그인 처리
          if (this.isLogin) {
            // 결제하기 일 경우
            if( this.isPay )
              this.goPayment( orderSeq );

            // 저장하기 일 경우
            else
              this.router.navigate( ['/mypage/order/list'] );

          } else {
            this.sMemberService.setLoginMemberNo(response['memberNo'] ).then(response => {
              if (response['ResultCode'] == true) {
                this.authService.login(response);

                if( this.isPay ) this.goPayment( orderSeq );
                else this.router.navigate( ['/mypage/order/list'] );
              } else {
                this.toastrService.error(response['ResultMessage'], '');
              }
            })
            .catch(response => {
              console.log( response );
            });
          }

        } else {
          this.toastrService.error(response['ResultMessage'], '주문하기 실패');
        }
      } )
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.form, this.formErrors);
      } );
    } else {
      this.toastrService.error('필수입력을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday(obj: NgbInputDatepicker, check: any) {
    if (check == 1) {
      this.form.patchValue( {
        rental_sdate: this.calendar.getToday()
      } );
      obj.close();
    } else if (check == 2) {
      this.form.patchValue( {
        rental_edate: this.calendar.getToday()
      } );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 날짜 선택 시 폼에 데이터 넣기
    입력값 : index 순번
    리턴값 : 없음
  *******************************************************************************/
  onDateSelect( $event: any, index: any) {
    const newSet = this.form.controls.set.value;

    newSet[index].install_date = $event;

    this.form.patchValue( {
      set: newSet
    } );
  }

  /*******************************************************************************
    설  명 : 주문 중지
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  orderStop() {
    this.toastrService.error('관리자 요청에 의해 일시적으로 주문하실 수 없습니다.', '');
  }

  /*******************************************************************************
    설  명 : 별도 문의 상품이 있는지 검사
    입력값 : 없음
    리턴값 : true / false
  *******************************************************************************/
  checkStatus() {
    // 별도문의 상품이 있는지 확인 해서 결제페이지로
    let check: boolean = false;
    let status: string = '2000';
    for(const item of this.form.controls.set.value) {
      for(const subItem of item.product) {
        if( subItem.inquiry_amt == '1' ) {
          check = true;
          break;
        }
      }
    }


    if( check ) status = '1000';

    this.form.patchValue({
      status: status
    });

    return check;
  }

  /*******************************************************************************
    설  명 : 결제하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  orderPay() {
    let check = this.checkStatus();

    if( check ) {
      this.toastrService.error('별도문의 상품을 주문하시는 경우 관리자의 승인이 필요합니다.', '결제하기');
      return false;
    }

    // 결제 페이지로 링크
    this.isPay = true;

    this.submit();
  }

  /*******************************************************************************
    설  명 : 결제하기 페이지로
    입력값 : orderSeq = 주문번호
    리턴값 : 없음
  *******************************************************************************/
  goPayment( orderSeq: any ) {
    this.router.navigate( ['/mypage/order/payment'], {
      relativeTo: this.activatedRoute,
      queryParams: {orderSeq: orderSeq },
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

}
