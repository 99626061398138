import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule, NgbActiveModal, NgbInputDatepicker, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatInputModule, MatTreeModule, MatPaginatorModule } from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxSummernoteModule } from 'ngx-summernote';

import { ToastrModule } from 'ngx-toastr';
import { DpDatePickerModule } from 'ng2-date-picker';
import { FileSelectDirective } from 'ng2-file-upload';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';

//import { Select2Module } from 'ng2-select2';
//import { LSelect2Module } from 'ngx-select2';

import { PipesModule } from '@app/pipes/pipes.module.ts';

/* 서비스 모듈 */
/* 공통 서비스 모듈 */
import { AuthGuard } from '@app/service/auth.guard';
import { AuthLoginCheck } from '@app/service/auth.login.check';
import { AuthService } from '@app/service/auth.service';
import { RestfulService } from '@app/service/restful.service';
import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { DaumAddressComponent } from '@app/component/daum-address/daum-address.component';
import { MemberService } from '@app/service/member.service';
import { CookieService } from 'ngx-cookie-service';

import { AutoFocusDirective } from '@app/directive/auto-focus.directive';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';

/* 홈페이지 모듈 */
import { SMainComponent } from '@shop/page/main/main.component';
import { SGnbComponent } from '@shop/page/main/gnb/gnb.component';
import { SQuickOrderComponent } from '@shop/page/main/quick-order/quick-order.component';
import { SHeaderComponent } from '@shop/page/main/header/header.component';
import { SFooterComponent } from '@shop/page/main/footer/footer.component';

import { SLoginComponent } from '@shop/page/member/login/login.component';
import { SFindIdpwComponent } from '@shop/page/member/find-idpw/find-idpw.component';
import { SResultFindidComponent } from '@shop/page/member/result-findid/result-findid.component';
import { SResultFindpwComponent } from '@shop/page/member/result-findpw/result-findpw.component';
import { SMemberJoinComponent } from '@shop/page/member/member-join/member-join.component';
import { SCompanySearchComponent } from '@shop/page/member/member-join/company-search/company-search.component';
import { SOrderPwfindComponent } from '@shop/page/member/login/order-pwfind/order-pwfind.component';
import { SNonMemberJoinComponent } from '@shop/page/member/non-member-join/non-member-join.component';

import { SGoodsDetailComponent } from '@shop/page/order/goods-detail/goods-detail.component';
import { SOrderComponent } from '@shop/page/order/order/order.component';
import { SOrderResultComponent } from '@shop/page/order/order/order-result/order-result.component';

import { SMypageComponent } from '@shop/page/member/mypage/mypage.component';
import { SMypageHeaderComponent } from '@shop/page/member/mypage/header/mypage-header.component';
import { SPwConfirmComponent } from '@shop/page/member/mypage/pw-confirm/pw-confirm.component';
import { SMemberEditComponent } from '@shop/page/member/mypage/member-edit/member-edit.component';
import { SMypageOrderComponent } from '@shop/page/member/mypage/order/mypage-order.component';
import { SOrderDetailComponent } from '@shop/page/member/mypage/order/order-detail/order-detail.component';
import { SOrderDetailEditInfoComponent } from '@shop/page/member/mypage/order/order-detail/edit-info/edit-info.component';
import { SCartComponent } from '@shop/page/member/mypage/cart/cart.component';
import { SCartEditComponent } from '@shop/page/member/mypage/cart/cart-edit/cart-edit.component';
import { SPaymentComponent } from '@shop/page/member/mypage/order/payment/payment.component';
import { SOrderCancelComponent } from '@shop/page/member/mypage/order/order-cancel/order-cancel.component';
import { SEstimateListComponent } from '@shop/page/member/mypage/estimate-list/estimate-list.component';
import { SEstimateComponent } from '@shop/page/member/mypage/estimate-list/estimate/estimate.component';
import { SEstimateRequestComponent } from '@shop/page/member/mypage/estimate-request/estimate-request.component';
import { SCancelListComponent } from '@shop/page/member/mypage/cancel-list/cancel-list.component';
import { SQuestionComponent } from '@shop/page/member/mypage/question/question.component';
import { SQuestionAddComponent } from '@shop/page/member/mypage/question/add/add.component';
import { SQuestionViewComponent } from '@shop/page/member/mypage/question/view/view.component';

import { STermsUseComponent } from '@shop/page/main/footer/policy/terms-use/terms-use.component';
import { SPolicyPrivacyComponent } from '@shop/page/main/footer/policy/policy-privacy/policy-privacy.component';
import { SPolicyEmailComponent } from '@shop/page/main/footer/policy/policy-email/policy-email.component';

import { SBoardComponent } from '@shop/page/board/board/board.component';
import { SBoardViewComponent } from '@shop/page/board/board/view/view.component';
import { SBoardListComponent } from '@shop/page/board/board/board-list/board-list.component';
import { SBoardGalleryComponent } from '@shop/page/board/board/board-gallery/board-gallery.component';
import { SBoardAlbumComponent } from '@shop/page/board/board/board-album/board-album.component';

import { SCompanyIntroduceComponent } from '@shop/page/Information/company-introduce/company-introduce.component';

import { SCompleteJoinComponent } from '@shop/page/member/member-join/complete-join/complete-join.component';
import { SPrintEstimateComponent } from '@shop/page/member/mypage/print/estimate/print-estimate.component';
import { SPrintStatementComponent } from '@shop/page/member/mypage/print/statement/print-statement.component';
import { SGheaderComponent } from '@shop/page/main/gheader/gheader.component';

import { SInistdpayCloseComponent } from '@shop/page/member/mypage/order/payment/inistdpay-close/inistdpay-close.component';
import { SInistdpayPopupComponent } from '@shop/page/member/mypage/order/payment/inistdpay-popup/inistdpay-popup.component';

import { SCancelEditComponent } from '@shop/page/member/mypage/cancel-list/edit/cancel-edit.component';
import { SCancelDetailComponent } from '@shop/page/member/mypage/cancel-list/detail/cancel-detail.component';

import { SUseGuideComponent } from '@shop/page/Information/use-guide/use-guide.component';

/* 홈페이지 모바일 */
import { MmainComponent } from '@mobile/page/main/main.component';
import { MheaderComponent } from '@mobile/page/main/header/header.component';
import { MquickOrderComponent } from '@mobile/page/main/quick-order/quick-order.component';
import { MfooterComponent } from '@mobile/page/main/footer/footer.component';
import { MgnbComponent } from '@mobile/page/main/gnb/gnb.component';
import { MloginComponent } from '@mobile/page/member/login/login.component';
import { MorderPwfindComponent } from '@mobile/page/member/login/order-pwfind/order-pwfind.component';
import { MfindIdpwComponent } from '@mobile/page/member/login/find-idpw/find-idpw.component';
import { MresultFindidpwComponent } from '@mobile/page/member/login/result-findidpw/result-findidpw.component';
import { MmemberJoinComponent } from '@mobile/page/member/member-join/member-join.component';
import { MpolicyPrivacyComponent } from '@mobile/page/member/member-join/policy/policy-privacy/policy-privacy.component';
import { MtermsUseComponent } from '@mobile/page/member/member-join/policy/terms-use/terms-use.component';
import { MgoodsDetailComponent } from '@mobile/page/order/goods-detail/goods-detail.component';
import { McompanySearchComponent } from '@mobile/page/member/member-join/company-search/company-search.component';
import { MmypageComponent } from '@mobile/page/member/mypage/mypage.component';
import { MmypageHeaderComponent } from '@mobile/page/member/mypage/header/header.component';
import { MpwConfirmComponent } from '@mobile/page/member/mypage/pw-confirm/pw-confirm.component';
import { MmemberEditComponent } from '@mobile/page/member/mypage/member-edit/member-edit.component';
import { MmypageOrderComponent } from '@mobile/page/member/mypage/order/mypage-order.component';
import { MorderDetailComponent } from '@mobile/page/member/mypage/order/order-detail/order-detail.component';
import { MpaymentComponent } from '@mobile/page/member/mypage/order/payment/payment.component';
import { McartComponent } from './mobile/page/member/mypage/cart/cart.component';
import { MestimateListComponent } from '@mobile/page/member/mypage/estimate-list/estimate-list.component';
import { MestimateRequestComponent } from '@mobile/page/member/mypage/estimate-request/estimate-request.component';
import { MestimateComponent } from '@mobile/page/member/mypage/estimate-list/estimate/estimate.component';
import { McancelListComponent } from '@mobile/page/member/mypage/cancel-list/cancel-list.component';
import { MorderCancelAddComponent } from '@mobile/page/member/mypage/cancel-list/add/add.component';
import { MorderCancelEditComponent } from '@mobile/page/member/mypage/cancel-list/edit/edit.component';
import { MquestionComponent } from '@mobile/page/member/mypage/question/question.component';
import { MquestionAddComponent } from '@mobile/page/member/mypage/question/add/add.component';
import { MquestionAnswerComponent } from '@mobile/page/member/mypage/question/answer/answer.component';

import { McompanyIntroduceComponent } from '@mobile/page/Information/company-introduce/company-introduce.component';

import { MboardComponent } from '@mobile/page/board/board.component';
import { MboardListComponent } from '@mobile/page/board/board-list/board-list.component';
import { MboardGalleryComponent } from '@mobile/page/board/board-gallery/board-gallery.component';
import { MboardViewComponent } from '@mobile/page/board/view/view.component';

import { MuseGuideComponent } from '@mobile/page/Information/use-guide/use-guide.component';

// 공용 컴포넌트
import { SCCartModalComponent } from '@shop/components/cart-modal/cart-modal.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  pagination: {
    el: '.swiper-pagination',
    type: 'fraction',
  },
};

/* 달력 컴포넌트 한글 화 */
const I18N_VALUES = {
  ko: {
    weekdays: ['월', '화', '수', '목', '금', '토', '일'],
    months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  }
};

@Injectable()
export class I18n {
  language = 'ko';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@NgModule({
  declarations: [
    AppComponent,

    AutoFocusDirective,

    SMainComponent,
    SGnbComponent,
    SQuickOrderComponent,
    SHeaderComponent,
    SFooterComponent,
    SLoginComponent,
    SFindIdpwComponent,
    SResultFindidComponent,
    SResultFindpwComponent,
    SMemberJoinComponent,
    SCompanySearchComponent,
    SOrderPwfindComponent,
    SNonMemberJoinComponent,
    SGoodsDetailComponent,
    SOrderComponent,
    SOrderResultComponent,
    SMypageComponent,
    SMypageHeaderComponent,
    SPwConfirmComponent,
    SMemberEditComponent,
    SMypageOrderComponent,
    SOrderDetailComponent,
    SOrderDetailEditInfoComponent,
    SCartComponent,
    SCartEditComponent,
    SPaymentComponent,
    SOrderCancelComponent,
    SEstimateComponent,
    SEstimateListComponent,
    SCancelListComponent,
    SQuestionComponent,
    SQuestionAddComponent,
    SQuestionViewComponent,
    STermsUseComponent,
    SPolicyPrivacyComponent,
    SPolicyEmailComponent,
    SBoardComponent,
    SBoardViewComponent,
    SBoardListComponent,
    SBoardGalleryComponent,
    SBoardAlbumComponent,
    SCompanyIntroduceComponent,
    SCompleteJoinComponent,
    SPrintEstimateComponent,
    SPrintStatementComponent,
    SGheaderComponent,
    SInistdpayCloseComponent,
    SInistdpayPopupComponent,
    SCCartModalComponent,
    SCancelEditComponent,
    SCancelDetailComponent,
    SUseGuideComponent,

    MmainComponent,
    MfooterComponent,
    MgnbComponent,
    MheaderComponent,
    MquickOrderComponent,
    MloginComponent,
    MfindIdpwComponent,
    MorderPwfindComponent,
    MresultFindidpwComponent,
    MmemberJoinComponent,
    MpolicyPrivacyComponent,
    MtermsUseComponent,
    MgoodsDetailComponent,
    McompanySearchComponent,
    MmypageComponent,
    MmypageHeaderComponent,
    MpwConfirmComponent,
    MmemberEditComponent,
    MmypageOrderComponent,
    MorderDetailComponent,
    MpaymentComponent,
    McartComponent,
    MestimateListComponent,
    MestimateRequestComponent,
    MestimateComponent,
    McancelListComponent,
    MorderCancelAddComponent,
    MorderCancelEditComponent,
    MquestionComponent,
    MquestionAddComponent,
    MquestionAnswerComponent,
    McompanyIntroduceComponent,
    MboardComponent,
    MboardListComponent,
    MboardGalleryComponent,
    MboardViewComponent,
    MuseGuideComponent,
    
    DaumAddressComponent,

    SEstimateRequestComponent
  ],
  imports: [
    PipesModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    //NgbModule.forRoot(),
    NgbModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTreeModule,
    MatPaginatorModule,
    DragDropModule,
    DpDatePickerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right'
    }),
    DeviceDetectorModule.forRoot(),
    SwiperModule,
    NgxSummernoteModule,
    //LSelect2Module,
  ],
  providers: [
    RestfulService,
    MemberService,
    UtilService,
    Globals,
    AuthService,
    AuthGuard,
    AuthLoginCheck,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    CookieService,
    NgbActiveModal,
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    NgbDatepickerConfig
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    SCompanySearchComponent,
    SQuestionAddComponent,
    SEstimateComponent,
    SOrderPwfindComponent,
    SCCartModalComponent,
    SOrderCancelComponent,
    SCancelEditComponent,
    SCancelDetailComponent,
    SUseGuideComponent,
    SOrderDetailEditInfoComponent,
    SCartEditComponent,

    McompanySearchComponent,
    MquestionAddComponent,
    MuseGuideComponent
  ]
})
export class AppModule { }
