import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { SMemberService } from '@shop/service/member.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-find-idpw',
  templateUrl: './find-idpw.component.html',
  styleUrls: ['./find-idpw.component.scss']
})
export class SFindIdpwComponent implements OnInit {

  errorResponse: ApiResponse;
  public findIdForm: FormGroup;
  public findPwForm: FormGroup;
  public formIdErrors = {
    'radio': '',
    'name': '',
    'email1': '',
    'email2': '',
    'hp1': '',
    'hp2': '',
  };

  public formPwErrors = {
    'radio': '',
    'id': '',
    'email1': '',
    'email2': '',
    'hp1': '',
    'hp2': '',
  };

  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public memberService: SMemberService,
    public authService: AuthService,
  ) {
    this.buildFindIdForm();
    this.buildFindPwForm();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildFindIdForm(): void {
    this.findIdForm = this.formBuilder.group({
      radio:[ 'hp', [Validators.required] ],
      name:[ '', [Validators.required, Validators.pattern('^[가-힣a-zA-Z]*$')] ],
      email1:[ '', [Validators.required] ],
      email2:[ '' ],
      hp1:[ '010' ],
      hp2:[ '', [Validators.required, Validators.pattern('^[0-9]{7,8}$')] ],
    });

    this.findIdForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.findIdForm, this.formIdErrors );
    });
  };

  buildFindPwForm(): void {
    this.findPwForm = this.formBuilder.group({
      radio:[ 'hp', [Validators.required] ],
      id:[ '', [Validators.required] ],
      email1:[ '', [Validators.required] ],
      email2:[ '' ],
      hp1:[ '010' ],
      hp2:[ '', [Validators.required, Validators.pattern('^[0-9]{7,8}$')] ],
    });

    this.findPwForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.findPwForm, this.formPwErrors );
    });
  };

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
  }

  ngDoCheck() {
    // 이메일 선택에 따른 패턴 부여
    if( this.findIdForm.get('radio').value == "email" ) {
      this.findIdForm.get('hp2').clearValidators();
      this.findIdForm.get('hp2').updateValueAndValidity();

      if( this.findIdForm.get('email2').value == "" )
        this.findIdForm.get('email1').setValidators( [Validators.required, Validators.pattern('^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')] );
      else
        this.findIdForm.get('email1').setValidators( [Validators.required, Validators.pattern('^[a-z0-9._-]*$')] );

      this.findIdForm.get('email1').updateValueAndValidity();
    } else {
      this.findIdForm.get('email1').clearValidators();
      this.findIdForm.get('email1').updateValueAndValidity();
      this.findIdForm.get('hp2').setValidators([Validators.required, Validators.pattern('^[0-9]{7,8}$')]);
      this.findIdForm.get('hp2').updateValueAndValidity();
    }

    // 이메일 선택에 따른 패턴 부여
    if( this.findPwForm.get('radio').value == "email" ) {
      this.findPwForm.get('hp2').clearValidators();
      this.findPwForm.get('hp2').updateValueAndValidity();

      if( this.findPwForm.get('email2').value == "" )
        this.findPwForm.get('email1').setValidators( [Validators.required, Validators.pattern('^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')] );
      else
        this.findPwForm.get('email1').setValidators( [Validators.required, Validators.pattern('^[a-z0-9._-]*$')] );

      this.findPwForm.get('email1').updateValueAndValidity();
    } else {
      this.findPwForm.get('email1').clearValidators();
      this.findPwForm.get('email1').updateValueAndValidity();
      this.findPwForm.get('hp2').setValidators([Validators.required, Validators.pattern('^[0-9]{7,8}$')]);
      this.findPwForm.get('hp2').updateValueAndValidity();
    }
  }

  /*******************************************************************************
    설  명 : SELECT 선택 처리
  *******************************************************************************/
  selectbox_pulldown(event) {

    var that = $(event.target);
    var findrowA  = that.closest("a");    //  클릭한 행의 A

    if (findrowA.parent().hasClass("pulldown")) {
      findrowA.parent().removeClass("pulldown")
    } else {
      findrowA.parent().addClass("pulldown")
    }
  }

  // 아이디 찾기 select
  selectChange1(event, element) {
    var that = $(event.target);
    var findrowA  = that.closest("a");    //  클릭한 행의 A

    findrowA.parent().parent().parent().find(".select-title > span").text(findrowA.text());
    $(".select-box").removeClass("pulldown");

    if( event.target.text == "직접입력" )
      this.findIdForm.get( element ).setValue( "" );
    else
      this.findIdForm.get( element ).setValue( event.target.text );
  }

  // 임시비밀번호 발급 select
  selectChange2(event, element) {
    var that = $(event.target);
    var findrowA  = that.closest("a");    //  클릭한 행의 A

    findrowA.parent().parent().parent().find(".select-title > span").text(findrowA.text());
    $(".select-box").removeClass("pulldown");

    if( event.target.text == "직접입력" )
      this.findPwForm.get( element ).setValue( "" );
    else
      this.findPwForm.get( element ).setValue( event.target.text );
  }

  email1() {
    $(".hp-div1").css("display", "none");
    $(".email-div1").css("display", "block");
  }

  hp1() {
    $(".hp-div1").css("display", "block");
    $(".email-div1").css("display", "none");
  }

  email2() {
    $(".hp-div2").css("display", "none");
    $(".email-div2").css("display", "block");
  }

  hp2() {
    $(".hp-div2").css("display", "block");
    $(".email-div2").css("display", "none");
  }

  /*******************************************************************************
    설  명 : 아이디 찾기 처리
  *******************************************************************************/
  public submitId() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.findIdForm, this.formIdErrors);

    if( this.findIdForm.valid ) {
      this.memberService.setFindId( this.findIdForm )
      .then(response => {
        if( response.ResultCode == true ) {
          this.toastrService.success(response.ResultMessage, '아이디 찾기');
          this.memberService.findResult = response.data;
          this.router.navigate(['/member/find/id/result']);
        } else {
          this.toastrService.error(response.ResultMessage, '아이디 찾기');
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.findIdForm, this.formIdErrors);
      });
    }
  }

  /*******************************************************************************
    설  명 : 임시비밀번호 발급 처리
  *******************************************************************************/
  public submitPw() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.findPwForm, this.formPwErrors);

    if( this.findPwForm.valid ) {
      this.memberService.setFindPw( this.findPwForm ).then(response => {
        if( response.ResultCode == true ) {
          this.toastrService.success(response.ResultMessage, '임시비밀번호 발급');
          this.memberService.findResult = response.data;
          this.router.navigate(['/member/find/pw/result']);
        } else {
          this.toastrService.error(response.ResultMessage, '임시비밀번호 발급');
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.findPwForm, this.formPwErrors);
      });
    }
  }

}
