import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mquestion-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss']
})
export class MquestionAnswerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	$("#mmypage-menu7").addClass("active");    
  }

}
