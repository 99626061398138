import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SMemberService } from '@shop/service/member.service';
import { SOrderService } from '@shop/service/order.service';
import { SBoardService } from '@shop/service/board.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mmypage',
  templateUrl: './mypage.component.html',
  styleUrls: ['./mypage.component.scss']
})
export class MmypageComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역변수 선언
  *******************************************************************************/
  public status1: number = 0;
  public status2: number = 0;
  public status3: number = 0;
  public status4: number = 0;
  public status5: number = 0;
  public status6: number = 0;

  public orderList: any = [];
  public estimateList: any = [];

  private end_date: any = moment().format('YYYY-MM-DD');
  private start_date: any = moment().subtract(1, 'months').format('YYYY-MM-DD');

  /*******************************************************************************
     설  명 : 생성자
  *******************************************************************************/
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private sMemberService: SMemberService,
    private sOrderService: SOrderService,
    private sBoardService: SBoardService,
  ) { }

  /*******************************************************************************
     설  명 : 데이터 로딩 처리
  *******************************************************************************/
  ngOnInit() {
    $("#mmypage-menu1").addClass("active");
    
    this.getMypageOrderCount();
    this.getOrderList();
    this.getBoardList();
  }

  /*******************************************************************************
     설  명 : 진행중인 주문 카운트 가져오기
  *******************************************************************************/
  getMypageOrderCount() {
    this.sMemberService.getMypageOrderCount().then(response => {
      if( response.ResultCode == true ) {
        
        response.data.forEach(item => {
          // 주문접수
          if( item.common_code == '1000' )
            this.status1 += parseInt(item.total);

          // 결제대기
          else if( item.common_code == '2000' )
            this.status2 += parseInt(item.total);

          // 결제완료
          else if( item.common_code == '3000' || item.common_code == '3010' )
            this.status3 += parseInt(item.total);

          // 임대중, 임대종료
          else if( item.common_code == '4000' ) {
            this.status4 += parseInt(item.total);
            this.status5 += parseInt(item.total2);
          
          // 환불/취소
          } else if( item.common_code > '4000' )
            this.status6 += parseInt(item.total);
        });

      } else {
        this.toastrService.error(response['ResultMessage'], '');
      }
    });
  }

  /*******************************************************************************
     설  명 : 주문내역 리스트 가져오기
  *******************************************************************************/
  async getOrderList() {
    let params: any = {
      row: 3, 
      no: 1, 
      start_date: this.start_date, 
      end_date: this.end_date
    };

    await this.sOrderService.getOrderList( params ).then( response => {
      if( response.ResultCode ) {
        this.orderList = response.data;
        //this.totalCount = parseInt( response.data.length );
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
     설  명 : 게시판 리스트 정보 가져오기
  *******************************************************************************/
  async getBoardList() {
    let params: any = {
      board_id: 'estimate', 
      row: 3, 
      no: 1, 
      start_date: this.start_date, 
      end_date: this.end_date
    };

    await this.sBoardService.getEstimateList( params ).then( response => {
      if( response.ResultCode ) {
        this.estimateList = response.data;
        //this.totalCount = parseInt( response.total );
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
     설  명 : 상세보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  view( row: any ) {
    this.router.navigate(
    ['/mypage/order/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: {orderSeq: row.seq},
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

}
