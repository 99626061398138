import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';

import { SBoardService } from '@shop/service/board.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-mboard-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class MboardViewComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public boardInfo: any = {};
  public params: any = {
    type: 'list',
    board_id: 'notice',
    group_id: '전체',
    row: 10,
    no: 1,
    group: 'subject',
    text: ''
  };

  public boardData: any = {};
  public memberInfo: any = {};

  public baseURL = config.baseUrl;

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sBoardService: SBoardService,
    private authService: AuthService,
    private cookieService: CookieService,
    private domSanitizer: DomSanitizer
  ) { }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
  *******************************************************************************/
  ngOnInit() {
    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? 'notice' : params.board_id;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' ) ? '전체' : params.group_id;
      this.params.board_seq = ( typeof params.board_seq == 'undefined' || params.board_seq == '' ) ? '' : params.board_seq;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? 'list' : params.type;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
      this.params.group = ( typeof params.group == 'undefined' || params.group == '') ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;

      if( this.params.board_seq == '' ) {
        this.toastrService.error( '게시물이 존재하지 않습니다.', '게시판' );

        this.router.navigate(
        ['/mobile/board/list'],
        {
          relativeTo: this.activatedRoute,
          queryParams: this.params,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });

        return false;
      }

      this.getBoardInfo( this.params.board_id );
    });
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 게시판 아이디
    리턴값 : 없음
  *******************************************************************************/
  getBoardInfo( board_id ) {
    this.sBoardService.getBoardInfoId( board_id ).then( response => {
      this.boardInfo = response.data;

      this.getBoardView( board_id, this.params.board_seq );
    });
  }

/*******************************************************************************
  설  명 : 게시글 정보 가져오기
  입력값 : 게시판 아이디, 게시판 코드
  리턴값 : 없음
*******************************************************************************/
  getBoardView( board_id, board_seq ) {
    let hitCookie = this.cookieService.get( board_id + board_seq );

    if( hitCookie == '' ) {
      this.cookieService.set( board_id + board_seq, 'true' );
      this.params.viewCheck = 1;
    } else {
      this.params.viewCheck = 0;
    }

    this.sBoardService.getBoardView( board_id, board_seq, this.params.viewCheck ).then( response => {
      if( response.ResultCode ) {
        this.boardData = response.data;
        
        // 그룹 표시
        if( this.boardData.group_id == '전체' || this.boardData.group_id == '' ) {
        } else {
          this.boardData.subject = '[' + this.boardData.group_id + '] ' + this.boardData.subject;
        }

        // new 표시
        if( this.boardData.new_check_date <= this.boardInfo.new_date ) {
          this.boardData.subject = this.boardData.subject;
        }

      } else {
        this.toastrService.error( response.ResultMessage, '게시글 조회' );

        this.router.navigate(
        ['/mobile/board/list'],
        {
          relativeTo: this.activatedRoute,
          queryParams: this.params,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  list() {
    this.params.type = '';

    this.router.navigate(
    ['/mobile/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 삭제 가능 여부 확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  authCheck() {
    // 관리자 권한일 경우 삭제 가능
    if( this.memberInfo.level >= 80 ) {
      return true;
    }

    // 작성자의 경우
    if( this.memberInfo.mem_no == this.boardData.writer ) return true;

    return false;
  }

  /*******************************************************************************
    설  명 : 게시글 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    if( confirm("게시글을 삭제하시겠습니까?") ) {
      this.sBoardService.deleteBoard( this.boardInfo.board_id, this.params.board_seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '게시글 삭제' );

          this.router.navigate(
          ['/mobile/board/list'],
          {
            relativeTo: this.activatedRoute,
            queryParams: this.params,
            queryParamsHandling: 'merge', // remove to replace all query params by provided
          });

        } else {
          this.toastrService.error( response.ResultMessage, '게시글 삭제' );
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 글 저장/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  write() {
    this.router.navigate(
    ['/mobile/board/list/add'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 주문가이드 텍스트 처리
    입력값 : 없음
    리턴값 : SafeHtml
  *******************************************************************************/
  getSafeHtml( html: any ): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

}
