import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { SMemberService } from '@shop/service/member.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mfind-idpw',
  templateUrl: './find-idpw.component.html',
  styleUrls: ['./find-idpw.component.scss']
})
export class MfindIdpwComponent implements OnInit {

  errorResponse: ApiResponse;
  public findForm: FormGroup;
  public formErrors = {};

  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public memberService: SMemberService,
    public authService: AuthService,
  ) {
    this.buildFindForm();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildFindForm(): void {
    this.findForm = this.formBuilder.group({
      gubun: [ 'id', [Validators.required] ],
      radio: [ 'email', [Validators.required] ],
      name: [ '', [] ],
      id: [ '', [] ],
      email1: [ '', [Validators.required] ],
      email2: [ '' ],
      hp1: [ '010' ],
      hp2: [ '', [Validators.required, Validators.pattern('^[0-9]{7,8}$')] ],
    });

    this.findForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.findForm, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
    $(".mheader>.left-area").addClass("blind");
    $(".mheader>.right-area").addClass("blind");
    $(".mfooter").addClass("blind");
  }

  ngDoCheck() {
    // 아이디/비밀번호 선택에 따른 패턴 부여
    if( this.findForm.get('gubun').value == 'id' ) {
      this.findForm.get('name').setValidators([Validators.required, Validators.pattern('^[가-힣a-zA-Z]*$')]);
      this.findForm.get('name').updateValueAndValidity();
      this.findForm.get('id').clearValidators();
      this.findForm.get('id').updateValueAndValidity();
    } else {
      this.findForm.get('name').clearValidators();
      this.findForm.get('name').updateValueAndValidity();
      this.findForm.get('id').setValidators([Validators.required, Validators.pattern('^[a-z0-9]{6,20}$')]);
      this.findForm.get('id').updateValueAndValidity();
    }

    // 이메일/휴대폰 선택에 따른 패턴 부여
    if( this.findForm.get('radio').value == 'email' ) {
      this.findForm.get('hp2').clearValidators();
      this.findForm.get('hp2').updateValueAndValidity();

      if( this.findForm.get('email2').value == "" ) {
        this.findForm.get('email1').setValidators( [Validators.required, Validators.pattern('^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')] );
      } else {
        this.findForm.get('email1').setValidators( [Validators.required, Validators.pattern('^[a-z0-9._-]*$')] );
      }

      this.findForm.get('email1').updateValueAndValidity();
    } else {
      this.findForm.get('email1').clearValidators();
      this.findForm.get('email1').updateValueAndValidity();
      this.findForm.get('hp2').setValidators([Validators.required, Validators.pattern('^[0-9]{7,8}$')]);
      this.findForm.get('hp2').updateValueAndValidity();
    }
  }

  ngOnDestroy() {
    $(".mheader>.left-area").removeClass("blind");
    $(".mheader>.right-area").removeClass("blind");
    $(".mfooter").removeClass("blind");
  }

  // selectbox
  selectbox_pulldown(event) {
    var that = $(event.target);
    var findrowA  = that.closest("a");    //  클릭한 행의 A

    if (findrowA.parent().hasClass("pulldown")) {
      findrowA.parent().removeClass("pulldown");
    } else {
      findrowA.parent().addClass("pulldown");
    }
  }

  // select
  selectChange(event, element) {
    var that = $(event.target);
    var findrowA  = that.closest("a");    //  클릭한 행의 A

    findrowA.parent().parent().parent().find(".select-title > span").text(findrowA.text());
    $(".select-box").removeClass("pulldown");

    if( event.target.text == "직접입력" ) {
      this.findForm.get( element ).setValue( "" );
    } else {
      this.findForm.get( element ).setValue( event.target.text );
    }
  }

  /*******************************************************************************
    설  명 : 아이디 찾기 처리
  *******************************************************************************/
  public submitId() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.findForm, this.formErrors);

    if( this.findForm.valid ) {
      this.memberService.setFindId( this.findForm )
      .then(response => {
        if( response.ResultCode == true ) {
          this.toastrService.success(response.ResultMessage, '아이디 찾기');

          sessionStorage.setItem( 'findRequest', 'id' );
          sessionStorage.setItem( 'findResponse', JSON.stringify(response.data) );

          this.router.navigate(['/mobile/result/findidpw']);
        } else {
          this.toastrService.error(response.ResultMessage, '아이디 찾기');
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.findForm, this.formErrors);
      });
    }
  }

  /*******************************************************************************
    설  명 : 임시비밀번호 발급 처리
  *******************************************************************************/
  public submitPw() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.findForm, this.formErrors);

    if( this.findForm.valid ) {
      this.memberService.setFindPw( this.findForm ).then(response => {
        if( response.ResultCode == true ) {
          this.toastrService.success(response.ResultMessage, '임시비밀번호 발급');

          sessionStorage.setItem( 'findRequest', 'pw' );
          sessionStorage.setItem( 'findResponse', JSON.stringify(response.data) );

          this.router.navigate(['/mobile/result/findidpw']);
        } else {
          this.toastrService.error(response.ResultMessage, '임시비밀번호 발급');
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.findForm, this.formErrors);
      });
    }
  }

}
