import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mterms-use',
  templateUrl: './terms-use.component.html',
  styleUrls: ['./terms-use.component.scss']
})
export class MtermsUseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
