import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})

export class MemberService {

  public memberInfo: any;

  constructor(
    private restful: RestfulService
  ) {
  }

  private handleErrorPromise(error: Response | any) {
    console.log(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 로그인정보 가져오기
  getMemberInfo(program: string): Promise<any> {
    return this.restful.get({
        program: program,
        service: 'member',
        version: '1.0',
        action: 'getMember'
      }, {
      }
    ).then( response => {
      if( response.ResultCode )
        this.memberInfo = response.data[0];
      else
        this.memberInfo = [];

      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 로그인
  login(program: string, id: string, pw: any): Promise<any> {
    return this.restful.post({
        program: program,
        service: 'member',
        version: '1.0',
        action: 'login'
      }, {
        id:id,
        pw:pw
      }
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

}
