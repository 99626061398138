import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-morder-pwfind',
  templateUrl: './order-pwfind.component.html',
  styleUrls: ['./order-pwfind.component.scss']
})
export class MorderPwfindComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  // 핸드폰번호 selectbox
  selectbox_pulldown(event) {

    var that = $(event.target);
    var findrowA  = that.closest("a");    //  클릭한 행의 A
      
    if (findrowA.parent().hasClass("pulldown")) {
      findrowA.parent().removeClass("pulldown")
    } else {
			findrowA.parent().addClass("pulldown")
    }
  }	
	
	// 핸드폰번호 select
	selectbox_select(event) {
    
    var that = $(event.target);
    var findrowA  = that.closest("a");    //  클릭한 행의 A
    
    findrowA.parent().parent().parent().find(".select-title > span").text(findrowA.text());
    $(".select-box").removeClass("pulldown");
	}	

}
