import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SProjectService {

  public selectSeq: number;

  public projectOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public projectSeq: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  get getProjectOpen() {
    return this.projectOpen.asObservable();
  }

  get getProjectSeq() {
    return this.projectSeq.asObservable();
  }

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  /*******************************************************************************
    설  명 : 프로젝트 링크 시 상단 표시 여부 데이터 처리
  *******************************************************************************/
  setProjectOpen( data ) {
		this.projectOpen.next( data );
  }

  /*******************************************************************************
    설  명 : 프로젝트 번호 데이터 처리
  *******************************************************************************/
  setProjectSeq( data ) {
		this.projectSeq.next( data );
  }

  /*******************************************************************************
    설  명 : 프로젝트 리스트 가져오기
  *******************************************************************************/
  getProjectList(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'project',
        version: '1.0',
        action: 'getProjectList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 프로젝트 정보 가져오기
  *******************************************************************************/
  getProjectInfo( seq ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'project',
        version: '1.0',
        action: 'getProjectInfo'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
