import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mpayment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class MpaymentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
		$("#mmypage-menu3").addClass("active");  
  }

	payment_kind1() {
		$(".kind-div1").removeClass("blind");	
		$(".kind-div2").addClass("blind");	
		$(".kind-div3").addClass("blind");		
	}

	payment_kind2() {
		$(".kind-div1").addClass("blind");	
		$(".kind-div2").removeClass("blind");		
		$(".kind-div3").addClass("blind");		
	}
	
	payment_kind3() {
		$(".kind-div1").addClass("blind");	
		$(".kind-div2").addClass("blind");
		$(".kind-div3").removeClass("blind");	
	}	
}
