import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SPaymentService {

  constructor(
    private restful: RestfulService,
    private toastrService: ToastrService,
  ) {
  }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  /*******************************************************************************
    설  명 : 장바구니 추가(여러개)
  *******************************************************************************/
  getStdPayInit( data: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'payment',
        version: '1.0',
        action: 'getStdPayInit'
      }, {
        data: data
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
