import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';
import { DiscountService } from '@app/service/discount.service';

import { SProjectService } from '@shop/service/project.service';
import { SOrderService } from '@shop/service/order.service';
import { SCartService } from '@shop/service/cart.service';
import { SBoardService } from '@shop/service/board.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-mcart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class McartComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역변수
  *******************************************************************************/
  public cartList: any = [];

  public boardData: any = {};

  public projectSeq: any = '';

  public refresh: string = 'false';
  public isAllChecked: boolean = true;
  public totalAmt: any = 0;

  public isLogIn$: Observable<boolean>;
  public isLogIn: boolean;

  /*******************************************************************************
     설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sProjectService: SProjectService,
    private sOrderService: SOrderService,
    private sCartService: SCartService,
    private sBoardService: SBoardService,
    private authService: AuthService,
    private cookieService: CookieService,
    private discountService: DiscountService,
    private domSanitizer: DomSanitizer,
    private globals: Globals,
  ) {
    this.isLogIn$ = this.authService.getIsLogin;

    this.isLogIn$.subscribe( login => {
      this.isLogIn = login;
    });
  }

  /*******************************************************************************
     설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    $(".mfooter").css("padding", "30px 20px 135px");
    $("#mmypage-menu4").addClass("active");

    this.getBoardViewIndex();
    
    this.getCartList();

    this.activatedRoute.queryParams.subscribe(params => {
      this.refresh = ( typeof params.refresh == 'undefined' || params.refresh == '' ) ? '' : params.refresh;
    });

		// 프로젝트 번호 가져오기
    this.sProjectService.getProjectSeq.subscribe(data => {
      this.projectSeq = data;
    });

    // 장바구니 변경 시
    this.sCartService.isCartChange.subscribe((value: any) => {
      if( value === true ) {
        this.getCartList();

        this.initCalc();
      }
    });
  }

  ngOnDestroy() {
  	$(".mfooter").css("padding", "30px 20px");
  }

  /*******************************************************************************
    설  명 : 게시글 정보 가져오기
    입력값 : 게시판 아이디, 게시판 코드
    리턴값 : 없음
  *******************************************************************************/
  getBoardViewIndex() {
    this.sBoardService.getBoardViewIndex( 'contents', '1000000060000000000' ).then( response => {
      if( response.ResultCode ) {
        this.boardData = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '장바구니 이용안내' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 전체 금액 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initCalc() {
    // 총 금액 계산
    this.totalAmt = 0;

    this.cartList.forEach(obj => {
      if( obj.isChecked == 1 ) {
        this.totalAmt = this.totalAmt + (obj.discount.total_amt * obj.qty);
      }
    });
  }

  /*******************************************************************************
    설  명 : 장바구니 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCartList() {

    // 회원인 경우
    if( this.isLogIn ) {
      this.sOrderService.getCartList().then( response => {
        if( response.ResultCode ) {
          let tmp: any = [];

          for( let item of response.data ) {
            tmp.push({
              seq: item.sp_seq,
              sale_amt: item.amt
            });

            item.discount = {
              seq: '',
              discount_rate: 0,
              discount_amt: 0,
              total_amt: 0
            };
          }

          this.discountService.getDiscountList( this.projectSeq, tmp ).then( result => {
            // 상품에 할인 정보 저장
            for( let item of result.product ) {
              for( let item2 of response.data ) {
                if( item.seq == item2.sp_seq )
                  item2['discount'] = item;
              }
            }

            this.cartList = response.data;

            this.initCalc();
          });

        } else {
          this.toastrService.error( response.ResultMessage, '장바구니' );
        }
      });

    // 비회원인 경우 쿠키에서 가져온다.
    } else {
      //let data: any = this.globals.load( 'thefirstpro_mobile_cart' );
      let data: any = this.globals.load( 'thefirstpro_cart' );
      let cart: any = ( data !== false ) ? data : [];
      let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];

      this.cartList = [];

      // 쿠키에 저장된 장바구니 상품의 정보를 가져온다.
      if( cartList.length > 0 ) {
        this.sOrderService.getCartProduct( cartList ).then( response => {
          if( response.ResultCode ) {
            for( let index1 in response.data ) {
              for( let index2 in cartList ) {
                if( response.data[index1].seq == cartList[index2].seq ) {
                  cartList[index2].name = response.data[index1].name;
                  cartList[index2].spec = response.data[index1].spec;
                  cartList[index2].file_path = response.data[index1].file_path;
                  cartList[index2].thumbnail_filename = response.data[index1].thumbnail_filename;
                  cartList[index2].stock_yn = response.data[index1].stock_yn;
                  cartList[index2].pay_unit = response.data[index1].pay_unit;
                }
              }
            }

            this.cartList = [];

            for( let item of cartList ) {
              this.cartList.push({
                cart_seq: 0,
                mem_no: '',
                sp_seq: item.seq,
                product_name: item.name,
                product_spec: item.spec,
                qty: item.count,
                unit_amt: item.sale_amt,
                amt: item.count * item.sale_amt,
                expire_date: '',
                del_yn: false,
                writer: '',
                write_date: '',
                changer: '',
                change_date: '',
                file_path: item.file_path,
                thumbnail_filename: item.thumbnail_filename,
                org_filename: '',
                file_name: '',
                isChecked: 1,
                stock_yn: item.stock_yn,
                pay_unit: item.pay_unit,
                discount: {
                  seq: '',
                  discount_rate: 0,
                  discount_amt: 0,
                  total_amt: 0
                }
              });
            }

            let tmp: any = [];

            for( let item of cartList ) {
              tmp.push({
                seq: item.seq,
                sale_amt: item.sale_amt
              });

              item.discount = {
                seq: '',
                discount_rate: 0,
                discount_amt: 0,
                total_amt: 0
              };
            }

            this.discountService.getDiscountList( this.projectSeq, tmp ).then( result => {
              // 상품에 할인 정보 저장
              for( let item of result.product ) {
                for( let item2 of this.cartList ) {
                  if( item.seq == item2.sp_seq )
                    item2['discount'] = item;
                }
              }

              this.initCalc();
            });
          }
        });
      }
    }

  }

  /*******************************************************************************
    설  명 : 개별 체크
  *******************************************************************************/
  setCheck( index: number ) {
    //this.cartList[ index ].isChecked = event.target.checked;
    this.cartList[ index ].isChecked = ( this.cartList[ index ].isChecked == 0 ) ? 1 : 0;

    let totalChecked = 0;
    this.cartList.forEach(item => {
      if( item.isChecked == 1 ) {
        totalChecked += 1;
      }
    });

    if( totalChecked == this.cartList.length ) {
      this.isAllChecked = true;
    } else {
      this.isAllChecked = false;
    }

    this.initCalc();
  }

  /*******************************************************************************
    설  명 : 전체 체크
  *******************************************************************************/
  setAllCheck( event ) {
    this.isAllChecked = event.target.checked;

    this.cartList.forEach(obj => {
      obj.isChecked = this.isAllChecked;
    });

    this.initCalc();
  }

  /*******************************************************************************
    설  명 : 수량 변경 시
  *******************************************************************************/
  changeCount( index: number, event: any ) {
    this.cartList[ index ].qty = event.target.value;

    if( this.cartList[ index ].qty < 1 ) this.cartList[ index ].qty = 1;
  }

  /*******************************************************************************
    설  명 : 수량 변경 시
  *******************************************************************************/
  clickCount( index: number, value: any ) {
    this.cartList[ index ].qty = parseInt(this.cartList[ index ].qty) + value;

    if( this.cartList[ index ].qty < 1 ) this.cartList[ index ].qty = 1;
  }

  /*******************************************************************************
    설  명 : 수량 변경 시
  *******************************************************************************/
  setEditCart( index: number ) {
    this.sCartService.setEditCart( this.cartList[ index ], index, this.cartList[ index ].qty );

    this.initCalc();
  }

  /*******************************************************************************
    설  명 : 장바구니 행 삭제
  *******************************************************************************/
  setDeleteRowCart( index: number ) {
    if( !confirm("선택하신 내역을 삭제하시겠습니까?") ) return false;

    this.sCartService.setDeleteRowCart( this.cartList[ index ], index );

    this.initCalc();
  }

  /*******************************************************************************
    설  명 : 장바구니 선택 삭제
  *******************************************************************************/
  setDeleteSelectCart( type ) {
    if( this.cartList.length < 1 ) {
      this.toastrService.error('삭제할 장바구니 내역이 없습니다.', '장바구니 선택 삭제');
      return false;
    } else {
      let count = 0;
      for( let item of this.cartList ) {
        if( item.isChecked == 1 ) {
          count++;
        }
      }

      if( count < 1 ) {
        this.toastrService.error('삭제할 장바구니를 선택하세요.', '장바구니 선택 삭제');
        return false;
      }
    }

    if( type == 'col' && !confirm("선택하신 내역을 삭제하시겠습니까?") ) return false;

    // 회원인 경우 DB에서 삭제
    if( this.isLogIn ) {
      this.sOrderService.setDeleteSelectCart( this.cartList ).then(response => {
        if( response['ResultCode'] == true ) {
          this.toastrService.success(response['ResultMessage'], '장바구니 선택 삭제');
          this.sCartService.isCartChange.next( true );
          this.getCartList();
        } else {
          this.toastrService.error(response['ResultMessage'], '장바구니 선택 삭제');
        }
      })
      .catch(response => {
        this.toastrService.error(response, '장바구니 선택 삭제');
      });

    // 비회원인 경우 쿠키에서 삭제
    } else {
      //let data: any = this.globals.load( 'thefirstpro_mobile_cart' );
      let data: any = this.globals.load( 'thefirstpro_cart' );
      let cart: any = ( data !== false ) ? data : [];
      let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];

      for( let index in this.cartList ) {
        // 삭제 처리
        if( this.cartList[index].isChecked ) {
          cartList.splice( index, 1 );
        }
      }

      for( let i = this.cartList.length - 1; i >= 0; i-- ) {
        if( this.cartList[i].isChecked ) {
          this.cartList.splice( i, 1 );
        }
      }

      //this.globals.save( 'thefirstpro_mobile_cart', cartList );
      this.globals.save( 'thefirstpro_cart', cartList );

      this.sCartService.isCartChange.next( true );
    }

    this.initCalc();
  }

  /*******************************************************************************
    설  명 : 장바구니 비우기
  *******************************************************************************/
  setDeleteCart() {
    if( this.cartList.length < 1 ) {
      this.toastrService.error('삭제할 장바구니 내역이 없습니다.', '장바구니 선택 삭제');
      return false;
    }

    if( !confirm("장바구니를 비우시겠습니까?") ) return false;

    this.sCartService.setDeleteCart();
    
    this.initCalc();
  }

  /*******************************************************************************
    설  명 : 견적서 출력
  *******************************************************************************/
  openEstimate() {
    let url = '/member/mypage/print/estimate';

    window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=790, height=800, left=0, top=0' );
  }

  /*******************************************************************************
    설  명 : 선택상품 주문
  *******************************************************************************/
  orderSelected() {
    let data: any = [];
    for( let item of this.cartList ) {
      if( item.isChecked ) {
        if( item.stock_yn == false ) {
          this.toastrService.error( '품절인 상품은 주문하실 수 없습니다.', item.product_name);
          return false;
        }
        data.push({
          seq: item.sp_seq,
          cart_seq: item.cart_seq,
          count: item.qty
        });
      }
    }

    if( data.length < 1 ) {
      this.toastrService.error('주문할 상품을 선택해주시기 바랍니다.', '선택상품주문');

    } else {
      data = JSON.stringify( data );

      this.router.navigate(
      ['/mobile/order'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          data: encodeURIComponent( data )
        },
        queryParamsHandling: '', // remove to replace all query params by provided
      });
    }
  }

  /*******************************************************************************
    설  명 : row별 상품 주문하기
    입력값 : index = row
    리턴값 : 없음
  *******************************************************************************/
  orderRow( index: any ) {
    let data: any = [];

    if( this.cartList[index].stock_yn == false ) {
      this.toastrService.error( '품절인 상품은 주문하실 수 없습니다.', this.cartList[index].product_name);
      return false;
    }
    data.push({
      seq: this.cartList[index].sp_seq,
      cart_seq: this.cartList[index].cart_seq,
      count: this.cartList[index].qty
    });

    data = JSON.stringify( data );

    this.router.navigate(
    ['/mobile/order'],
    {
      relativeTo: this.activatedRoute,
      queryParams: {
        data: encodeURIComponent( data )
      },
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 전체상품 주문
  *******************************************************************************/
  orderAll() {
    let data: any = [];
    for( let item of this.cartList ) {
      if( item.stock_yn == false ) {
        this.toastrService.error( '품절인 상품은 주문하실 수 없습니다.', item.product_name);
        return false;
      }

      data.push({
        seq: item.sp_seq,
        cart_seq: item.cart_seq,
        count: item.qty
      });
    }

    if( data.length < 1 ) {
      this.toastrService.error('주문할 상품을 선택해주시기 바랍니다.', '전체주문');

    } else {
      data = JSON.stringify( data );

      this.router.navigate(
      ['/mobile/order'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          data: encodeURIComponent( data )
        },
        queryParamsHandling: '', // remove to replace all query params by provided
      });
    }
  }

  /*******************************************************************************
    설  명 : 주문가이드 텍스트 처리
    입력값 : 없음
    리턴값 : SafeHtml
  *******************************************************************************/
  getSafeHtml( html: any ): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

}
