import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'DateFormat'
})
export class DateFormatPipe implements PipeTransform {
  constructor() {
    moment.locale('ko');
  }

  public transform(value: any, type: string): string {
    return value ? moment(value).format(type) : null;
  }
}