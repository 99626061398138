import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mresult-findidpw',
  templateUrl: './result-findidpw.component.html',
  styleUrls: ['./result-findidpw.component.scss']
})
export class MresultFindidpwComponent implements OnInit {

	public findRequest: any = '';
	public findResponse: any = {};

  constructor(
		private router: Router
	) {
    if ( sessionStorage.getItem( 'findRequest' ) == null || sessionStorage.getItem( 'findResponse' ) == null ) {
      this.router.navigate(['/mobile/find/idpw']);
    }
	}

  ngOnInit() {
  	$(".mheader>.left-area").addClass("blind");
  	$(".mheader>.right-area").addClass("blind");
		$(".mfooter").addClass("blind");

		this.findRequest = sessionStorage.getItem( 'findRequest' );
		this.findResponse = JSON.parse(sessionStorage.getItem( 'findResponse' ));

		sessionStorage.removeItem( 'findRequest' );
		sessionStorage.removeItem( 'findResponse' );
  }

  ngOnDestroy() {
   	$(".mheader>.left-area").removeClass("blind");
   	$(".mheader>.right-area").removeClass("blind");
  	$(".mfooter").removeClass("blind");
  }

}
