import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { config } from '@app/service/config.service';
import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';
import { SOrderService } from '@shop/service/order.service';
import { SCartService } from '@shop/service/cart.service';
import { DiscountService } from '@app/service/discount.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

import { SEstimateComponent } from '@shop/page/member/mypage/estimate-list/estimate/estimate.component';
import { SCCartModalComponent } from '@shop/components/cart-modal/cart-modal.component';
import { ContentObserver } from '@angular/cdk/observers';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionsSM: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-mquick-order',
  templateUrl: './quick-order.component.html',
  styleUrls: ['./quick-order.component.scss']
})
export class MquickOrderComponent implements OnInit {

  @Input() isQuickOpen: any;
  // @Input() quickOrderList: any;
  @Input() isProject: boolean = false;

  @Input() set quickOrderInput(value: any) {
    this.quickOrderList = value;

    this.setTotalPrice();
  }

  public quickOrderList: any = [];

  isLogIn$: Observable<boolean>;
  isLogIn: boolean;

  public baseURL: string = config.baseUrl;

  public totalPrice: number = 0;

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sOrderService: SOrderService,
    private sCartService: SCartService,
    private modalService: NgbModal,
    public authService: AuthService,
    private cookieService: CookieService,
    private discountService: DiscountService,
    private globals: Globals,
  ) {
    this.isLogIn$ = this.authService.getIsLogin;

    this.isLogIn$.subscribe( login => {
      this.isLogIn = login;
    });

  }

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
  }

  // 숫자증가
  qty_plus(event) {
    var that = $(event.target);
    var findrowA  = that.closest('i'); 

    let tmp: any = findrowA.parent().find(".qty").val();

    findrowA.parent().find(".qty").val(parseInt( tmp ) + 1 );
  }

  // 숫자감소
  qty_minus(event) {
    var that = $(event.target);
    var findrowA  = that.closest('i'); 

    let tmp: any = findrowA.parent().find(".qty").val();

    findrowA.parent().find(".qty").val(parseInt(tmp) - 1 );
    if (findrowA.parent().find(".qty").val() == 0) {
        findrowA.parent().find(".qty").val(1);
    }
  }

  /*******************************************************************************
    설  명 : 빠른주문창 슬라이드
  *******************************************************************************/
  slideQuickOrder() {
    this.sCartService.quickOpen.next( false );
    this.sCartService.setMask( false );
  }

  /*******************************************************************************
    설  명 : 화면 맨 위로 이동
  *******************************************************************************/
  top() {
    $('html, body').animate({
      scrollTop : 0
      }, 0);
  }

  /*******************************************************************************
    설  명 : 빠른주문 리스트 전체 삭제
  *******************************************************************************/
  deleteAllQuickOrder() {
    this.sCartService.setDeleteCart();

    // this.setTotalPrice();
  }

  /*******************************************************************************
    설  명 : 빠른주문 리스트 선택 삭제
  *******************************************************************************/
  deleteQuickOrder( index: number ) {
    this.sCartService.setDeleteRowCart( this.quickOrderList[index], index );

    this.sCartService.setCartBadge( this.quickOrderList.length );

    // this.setTotalPrice();
  }

  /*******************************************************************************
    설  명 : 수량 변경 시
  *******************************************************************************/
  changeCount( index: number, event ) {
    this.sCartService.setEditCart( this.quickOrderList[index], index, event.target.value );

    // this.setTotalPrice();
  }

  clickCount( index: number, value ) {
    this.quickOrderList[ index ].count = parseInt(this.quickOrderList[ index ].count) + value;

    if( this.quickOrderList[ index ].count < 1 ) this.quickOrderList[ index ].count = 1;

    this.sCartService.setEditCart( this.quickOrderList[index], index, this.quickOrderList[ index ].count );

    // this.setTotalPrice();
  }

  /*******************************************************************************
    설  명 : 전체 주문금액 구하기
  *******************************************************************************/
  setTotalPrice() {
    let total = 0;
    this.quickOrderList.forEach(obj => {
      //let amt = obj.sale_amt * obj.count;
      // let amt = obj.discount.total_amt * obj.count;
      // total += amt;
      total += parseInt(obj.discount.total_amt);
    });

    this.totalPrice = total;
  }

  /*******************************************************************************
    설  명 : 견적요청
  *******************************************************************************/
  writeEstimate() {
    /*
    const modalRef = this.modalService.open(SEstimateComponent, options);

    const params = {
      contents: ''
    };

    this.quickOrderList.forEach(obj => {
      let row = obj.name + " " + obj.count + "개";
      params.contents = params.contents + row + "\r";
    });

    modalRef.componentInstance.boardId = 'estimate';
    modalRef.componentInstance.quickData = params;

    modalRef.result.then((result) => {
      this.router.navigate( ['/mobile/member/mypage/estimate'] );
    }, (reason) => {
    });
    */
    this.toastrService.warning( '견적문의는 PC에서 이용하시기 바랍니다.', '' );
  }

  /*******************************************************************************
    설  명 : 주문하기
  *******************************************************************************/
  orderLoginCheck( type ) {
    /*
    const modalRef = this.modalService.open(SCCartModalComponent, optionsSM);

    modalRef.result.then((result) => {
      console.log( result );
    }, (reason) => {
      console.log( reason );
    });

    if( confirm("비회원으로 주문하시겠습니까?\r취소를 누르면 로그인 페이지로 이동합니다.") ) {

      this.router.navigate(
      ['/member/non'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {type: type},
        queryParamsHandling: '', // remove to replace all query params by provided
      });
    } else {
      this.router.navigate( ['/login'] );
    }
    */
  }

  /*******************************************************************************
    설  명 : 주문하기
  *******************************************************************************/
  setOrder() {
    this.toastrService.error('현재 모바일에선 주문하실 수 없습니다.', '주문하기');
    /*
    let data: any = [];
    for( let item of this.quickOrderList ) {
      data.push({
        seq: item.seq,
        count: item.count
      });
    }

    // 빠른주문창 닫기
    this.sOrderService.setQuickOpen( false );

    data = JSON.stringify( data );

    this.router.navigate(
    ['/order'],
    {
      relativeTo: this.activatedRoute,
      queryParams: {
        data: encodeURIComponent( data )
      },
      queryParamsHandling: '', // remove to replace all query params by provided
    });
    */
  }

}
