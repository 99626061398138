import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { AuthService } from '@app/service/auth.service';

import { SBoardService } from '@shop/service/board.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mboard',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class MboardComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public boardInfo: any = {};
  public memberInfo: any = {};
  
  public params: any = {
    type: 'list',
    board_id: 'notice',
    group_id: '전체',
    row: 10,
    no: 1,
    group: 'subject',
    text: ''
  };

  public linecnt: any = [];
  public groupTab: any = [];

  public boardList = [];

  public totalCount: number;

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
		private router: Router,
    private activatedRoute: ActivatedRoute,
		private sBoardService: SBoardService,
		private authService: AuthService,
  ) { }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit() {
    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? 'notice' : params.board_id;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' ) ? '전체' : params.group_id;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? '' : params.type;
      if( this.params.board_id == 'install' ) {
        this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 12 : parseInt(params.row);
      } else {
        this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      }
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
      this.params.group = ( typeof params.group == 'undefined' || params.group == '') ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;

      this.getBoardInfo( this.params.board_id );
    });
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getBoardInfo( board_id ) {
    await this.sBoardService.getBoardInfoId( board_id ).then( response => {
      if( response.data == null ) {
        this.toastrService.error( "존재하지 않는 게시판입니다.", "게시판 에러" );

        this.router.navigate( ['/mobile/main'] );
        return false;
      }

      this.boardInfo = response.data;

      if( this.params.type == '' ) {
        switch( this.boardInfo.lvt_default ) {
          case 'A': this.params.type = 'album'; break;
          case 'G': this.params.type = 'gallery'; break;
          case 'C': this.params.type = 'calendar'; break;
          default: case 'L': this.params.type = 'list';
        }
      }

      if( this.params.type == 'list' ) this.linecnt = this.boardInfo.linecnt_list.split('/');
      else if( this.params.type == 'album' ) this.linecnt = this.boardInfo.linecnt_album.split('/');
      else if( this.params.type == 'gallery' ) this.linecnt = this.boardInfo.linecnt_gallery.split('/');

      if( this.boardInfo.lvt_group == '1' ) {
        this.boardInfo.group = '전체|' + this.boardInfo.group;
        this.groupTab = this.boardInfo.group.split('|');
      }

      this.getBoardList();
    });
  }

  /*******************************************************************************
    설  명 : 게시판 리스트 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardList() {
    this.sBoardService.getBoardList( this.params ).then( response => {
      this.boardList = response.data;
      this.boardInfo.total_count = response.total;

      this.totalCount = parseInt( response.total );

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : 게시판 리스트 타입 변경
    입력값 : type = L, A, C, G
    리턴값 : 없음
  *******************************************************************************/
  viewChange( type ) {
    this.params.type = type;

    this.router.navigate(
    ['/mobile/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 글 쓰기 권한
    입력값 : 없음
    리턴값 : true / false
  *******************************************************************************/
  authWriteCheck(): boolean {
    if( this.boardInfo.auth_write <= this.memberInfo.level ) {
      return true;
    } else {
      return false;
    }
  }

  /*******************************************************************************
    설  명 : 글 저장/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  write() {
    this.router.navigate(
    ['/mobile/board/list/add'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.params.no = page;

    this.router.navigate(
    ['/mobile/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 페이지 리스트 새로고침
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadList( group_id ) {
    this.params.group_id = group_id;

    this.router.navigate(
    ['/mobile/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  list() {
    $(".list").css("display", "block");
    $(".gallery").css("display", "none");
    $(".qna").css("display", "none");
  }

  gallery() {
    $(".list").css("display", "none");
    $(".gallery").css("display", "block");
    $(".qna").css("display", "none");
  }

  qna() {
    $(".qna").css("display", "table-row");
  }

}
