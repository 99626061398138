import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'comma'
})
export class KCurrencyPipe implements PipeTransform {
  constructor() {
  }

  public transform( value: any ): string {
    var str = String(value);

    return value ? str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') : '0';
  }
}