import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { SOrderService } from '@shop/service/order.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-print-statement',
  templateUrl: './print-statement.component.html',
  styleUrls: ['./print-statement.component.scss']
})
export class SPrintStatementComponent implements OnInit {

  public project: any = {};

  public detailList: any = [];
  public payList: any = [];

  private params: any = {
    seq: ''
  };

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sOrderService: SOrderService,
  ) { }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  ngOnInit() {
    // 파라미터 가져오기
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '' : params.seq;

      this.getOrderPrintList();
      this.getOrderPayList();
    });
  }

  /*******************************************************************************
    설  명 : 주문 상세내역 가져오기
  *******************************************************************************/
  getOrderPrintList() {
    this.sOrderService.getOrderPrintList( this.params.seq ).then( response => {
      if( response.ResultCode ) {
        this.detailList = response.data;
      } else {
        this.detailList = [];
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문 결제내역 가져오기
  *******************************************************************************/
  getOrderPayList() {
    this.sOrderService.getOrderPayList( this.params.seq ).then( response => {
      if( response.ResultCode ) {
        this.project = response.data;

        let date: any = '';
        date = this.project.order_date.split('-');
        this.project.order_date = date[0] + '년 ' + date[1] + '월 ' + date[2] + '일';

        this.payList = response.pay;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 프린트 버튼 처리
  *******************************************************************************/
  btnPrint() {
    setTimeout (() => {
      window.print();
    }, 1000);
  }

}
