import { async } from '@angular/core/testing';
/*******************************************************************************
  설  명 : 앱 설정
  작성일 :
  작성자 : 송영석
*******************************************************************************/
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';
import { RestfulService } from '@app/service/restful.service';
import { DiscountService } from '@app/service/discount.service';
import { SOrderService } from '@shop/service/order.service';
import { SProjectService } from '@shop/service/project.service';
import { SCartService } from '@shop/service/cart.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public title = 'The First';
  public commonCSS:SafeResourceUrl;

  public checkAdminPage:boolean = false;    // 관리자 페이지 여부
  public checkLoginPage:boolean = false;    // 관리자 로그인 페이지 여부
  public checkMobilePage:boolean = false;   // 모바일 페이지 체크
  public checkPrintPage: boolean = false;   // 프린트 페이지인지 검사
  public checkProject: boolean = false;     // 프로젝트 링크 시 상단 표시 여부

  public isQuickOpen: boolean = false;      // 빠른주문 열림 상태
  public isMask: boolean = false;      // 마스크 열림 상태
  public quickOrderList: any = [];               // 빠른주문 데이터

	public isLogIn$: Observable<boolean>;
	public isLogIn: boolean;
	
  public params: any = {
    projectSeq: ''
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public location: Location,
    public sanitizer: DomSanitizer,
    public globals: Globals,
    public restful: RestfulService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private sOrderService: SOrderService,
    private sCartService: SCartService,
    private sProjectService: SProjectService,
    private discountService: DiscountService
  ) {
    this.isLogIn$ = this.authService.getIsLogin;
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public ngOnInit() {
    // 파라미터 가져오기
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.projectSeq = ( typeof params.projectSeq == 'undefined' || params.projectSeq == '' ) ? '' : params.projectSeq;

      // 프로젝트 번호가 있을 시 상단 표시
      if( this.params.projectSeq != '' ) {
        this.sProjectService.setProjectOpen( true );
        this.sProjectService.setProjectSeq( this.params.projectSeq );
      }

      this.isLogIn$.subscribe( login => {
        this.isLogIn = login;

        this.getCartList();
      });
    });

    this.sCartService.mask.subscribe((data) => {
      this.isMask = data;
    });

    this.sProjectService.projectOpen.subscribe((data) => {
      this.checkProject = data;
    });

    // 퀵 장바구니 열기
    this.sCartService.quickOpen.subscribe((data) => {
      this.isQuickOpen = data;
    });

    // 장바구니 변경 시
    this.sCartService.isCartChange.subscribe((value: any) => {
      if( value === true ) {
        this.getCartList();
      }
    });

    if( this.location.path().indexOf('/print') > -1 ) {
      this.checkPrintPage = true;
    }
  }

  /*******************************************************************************
    설  명 : 관리자 로그인 페이지 체크, 페이지 변경 라우터에서 구현하면 되지 않을까?
             반복적으로 호출되기 때문에 최소로 구현
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public ngDoCheck() {
    if( location.pathname.indexOf( '/admin' ) > -1 ) {
      if( location.pathname.indexOf( '/login' ) > -1 || location.pathname == '/admin' ) this.checkLoginPage = true;
      else this.checkLoginPage = false;
    }
  }

  /*******************************************************************************
    설  명 : 데이터 표시 후 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public ngAfterContentInit() {
    if( location.pathname.indexOf( '/admin' ) > -1 ) {
      if( this.commonCSS == undefined )
        this.commonCSS = this.getCommonCSS( true );

      // 관리자 페이지 설정
      this.checkAdminPage = true;

    } else {

      // 모바일 임시코딩------------------------------------------
      if( location.pathname.indexOf( '/mobile' ) > -1 ) {
        this.checkMobilePage = true;
      }
      // 모바일 임시코딩------------------------------------------

      // 동적 css 로딩
      if( this.commonCSS == undefined )
        this.commonCSS = this.getCommonCSS( false );

      this.checkAdminPage = false;
    }
  }

  /*******************************************************************************
    설  명 : 관리자/쇼핑몰에 따라 css 파일
    입력값 : check = true admin, false shop
    리턴값 : css 파일 URL
  *******************************************************************************/
  private getCommonCSS( check: boolean ) {

    let css: string = '';

    if( check ) {
      css = '/assets/admin/css/common.css';
    } else {
      if( this.checkMobilePage ) {
        css = '/assets/mobile/css/common.css';
      } else {
        css = '/assets/shop/css/common.css';
      }
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl( css );
  }

  /*******************************************************************************
    설  명 : slide 메뉴 닫기
  *******************************************************************************/
  slideClose() {
    this.sCartService.quickOpen.next( false );
    this.sCartService.setMGnbOpen( false );
    this.sCartService.setMask( false );
  }

  /*******************************************************************************
    설  명 : 장바구니 리스트 가져오기
  *******************************************************************************/
  async getCartList() {
    if( this.isLogIn ) {
      await this.sOrderService.getCartList().then( async response => {
        if( response.ResultCode ) {
          let tmp: any = [];

          for( let item of response.data ) {
            tmp.push({
              seq: item.sp_seq,
              sale_amt: item.unit_amt * item.qty,
              discount_amt: item.total_amt
            });

            item.discount = {
              seq: '',
              discount_rate: 0,
              discount_amt: 0,
              total_amt: 0
            };
          }

          await this.discountService.getDiscountList( this.params.projectSeq, tmp ).then( result => {
            // 상품에 할인 정보 저장
            for( let item of result.product ) {
              for( let item2 of response.data ) {
                if( item.seq == item2.sp_seq )
                  item2['discount'] = item;
              }
            }

            this.quickOrderList = response.data;
          });

        } else {
          this.toastrService.error( response.ResultMessage, '장바구니' );
        }
      });

    // 비회원인 경우 쿠키에서 가져온다.
    } else {
      let data: any = this.globals.load( 'thefirstpro_cart' );
      let cart: any = ( data !== false ) ? data : [];
      let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];      

      //this.quickOrderList = [];

      // 쿠키에 저장된 장바구니 상품의 정보를 가져온다.
      if( cartList.length > 0 ) {
        await this.sOrderService.getCartProduct( cartList ).then( async response => {
          if( response.ResultCode ) {
            for( let index1 in response.data ) {
              for( let index2 in cartList ) {
                if( response.data[index1].seq == cartList[index2].seq ) {
                  cartList[index2].name = response.data[index1].name;
                  cartList[index2].spec = response.data[index1].spec;
                  cartList[index2].file_path = response.data[index1].file_path;
                  cartList[index2].thumbnail_filename = response.data[index1].thumbnail_filename;
                  cartList[index2].stock_yn = response.data[index1].stock_yn;
                  cartList[index2].inquiry_amt = response.data[index1].inquiry_amt;
                  cartList[index2].pay_unit = response.data[index1].pay_unit;
                  cartList[index2].min_qty = response.data[index1].min_qty;
                  cartList[index2].parent_category_code = response.data[index1].parent_category_code;
                  cartList[index2].category_code = response.data[index1].category_code;
                }
              }
            }

            //this.quickOrderList = [];

            let tmpList = [];
            for( let item of cartList ) {
              tmpList.push({
                cart_seq: 0,
                mem_no: '',
                sp_seq: item.seq,
                product_name: item.name,
                product_spec: item.spec,
                min_qty: item.min_qty,
                qty: item.count,
                count: item.count,
                unit_amt: item.sale_amt,
                amt: item.count * item.sale_amt,
                inquiry_amt: item.inquiry_amt,
                expire_date: '',
                del_yn: false,
                writer: '',
                write_date: '',
                changer: '',
                change_date: '',
                file_path: item.file_path,
                org_filename: '',
                file_name: '',
                thumbnail_filename: item.thumbnail_filename,
                isChecked: 1,
                stock_yn: item.stock_yn,
                pay_unit: item.pay_unit,
                parent_category_code: item.parent_category_code,
                category_code: item.category_code,
                discount: {
                  seq: '',
                  discount_rate: 0,
                  discount_amt: 0,
                  total_amt: 0
                }
              });
            }

            let tmp: any = [];

            for( let item of cartList ) {
              tmp.push({
                seq: item.seq,
                //sale_amt: item.sale_amt
                sale_amt: item.sale_amt * item.count
              });

              item.discount = {
                seq: '',
                discount_rate: 0,
                discount_amt: 0,
                total_amt: 0
              };
            }

            await this.discountService.getDiscountList( this.params.projectSeq, tmp ).then( result => {
              // 상품에 할인 정보 저장
              for( let item of result.product ) {
                for( let item2 of tmpList ) {
                  if( item.seq == item2.sp_seq )
                    item2['discount'] = item;
                }
              }
            });

            this.quickOrderList = tmpList;
          }
        });
      } else {
        this.quickOrderList = [];
      }
    }

  }

}
